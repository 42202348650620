import { Tag } from 'antd';

const TagItem = ({ handleClose, value, name, closable }) => {
    return (
        <Tag
            style={{
                borderRadius: '6px',
                border: 'none',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: '14px',
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
                gap: '6px',
                backgroundColor: 'white',
                boxShadow: '0px 0px 41px rgba(123, 153, 255, 0.15)',
            }}
            closable={closable}
            onClose={(e) => {
                e.preventDefault();
                handleClose(value);
            }}
        >
            {name}
        </Tag>
    );
};

export default TagItem;
