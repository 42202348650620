import endpoints from './endpoints';
import checkAuth from './checkAuth';
import { BASE_DOMAIN } from './endpoints';
const headers = {
    'Content-type': 'application/json',
    Accept: 'application/json',
};

class authService {
    auth = async (data) => {
        try {
            let res = await fetch(endpoints.auth, {
                method: 'POST',
                // mode: 'no-cors',
                headers,
                body: JSON.stringify(data),
            });

            return await res.json();
        } catch (err) {
            console.log(err);
        }
    };

    orderSort = async (token, type, IDs) => {
        try {
            let res = await fetch(`${BASE_DOMAIN}/catalog/sorting`, {
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    IDs,
                    list: type,
                }),
            });

            return await checkAuth(res);
        } catch (err) {
            console.log(err);
        }
    };

    getFrontStatus = async (token) => {
        try {
            let res = await fetch(endpoints.getStatusFront, {
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`,
                },
            });

            return await checkAuth(res);
        } catch (err) {
            console.log(err);
        }
    };

    updateFrontStatus = async (token) => {
        try {
            let res = await fetch(endpoints.updateStatusFront, {
                method: 'POST',
                headers: {
                    ...headers,
                    Authorization: `Bearer ${token}`,
                },
            });

            return await checkAuth(res);
        } catch (err) {
            console.log(err);
        }
    };

    checkDomain = async () => {
        try {
            let res = await fetch(endpoints.checkDomain, {
                method: 'POST',
            });

            return await res.json();
        } catch (err) {
            console.log(err);
        }
    };

    getCities = async () => {
        try {
            let res = await fetch(endpoints.getCitiesList, {
                method: 'POST',
            });

            return await res.json();
        } catch (err) {
            console.log(err);
        }
    };
}

export default authService;
