import React, { useState, useEffect } from 'react';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import './NavigationPage.scss';
import { id } from 'date-fns/locale';
// import "react-quill/dist/quill.snow.css";
// import parse from "html-react-parser";
import { nanoid } from 'nanoid';
// import ReactQuill, { Quill } from 'react-quill';
// import { htmlEditButton } from 'quill-html-edit-button';

// Quill.register('modules/htmlEditButton', htmlEditButton);
import { useNavigate } from 'react-router-dom';

const AddNavigation = () => {
    const nav = useNavigate();
    const { token, city } = useSelector((state) => state);
    const [title, setTitle] = useState('');
    const [descr, setDescr] = useState('');
    const [pageContent, setPageContent] = useState([]);

    const getVacancies = async () => {
        try {
            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ page: 'Navigation', cityId: city.id }),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            setPageContent(responseData.content.ru);
            console.log(responseData.content.ru);
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    useEffect(() => {
        getVacancies();
    }, []);

    const onSubmit = async () => {
        try {
            const requestBody = {
                page: 'Navigation',
                content: {
                    ru: pageContent?.length
                        ? [...pageContent, { name: title, url: descr, id: nanoid() }]
                        : [{ name: title, url: descr, id: nanoid() }],
                    // ru: [{title: title, descr: descr, id: 1}]
                },
                cityId: city.id,
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            // console.log(responseData);
            // notification.success({ message: "Успешно добавлено" });
            // setTitle("");
            // setDescr("");
            nav('/navigation');
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    return (
        <div style={{ paddingTop: '110px', paddingLeft: '20px', width: '100%' }}>
            <Button text={'Вернуться'} onClick={() => window.history.back()} />
            <div
                style={{
                    background: 'white',
                    padding: '24px 16px',
                    borderRadius: '12px',
                    width: '800px',
                    margin: '20px auto 0',
                    display: 'block',
                }}
            >
                <h2 style={{ fontSize: '20px', textAlign: 'center' }}>Добавление</h2>
                <div
                    style={{
                        display: 'flex',
                        gap: '20px',
                        flexWrap: 'wrap',
                        flexDirection: 'column',
                        width: '75%',
                        margin: '20px auto 0',
                    }}
                >
                    <TextArea
                        className='vacanciesPage__textarea'
                        placeholder='Название'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        rows={2}
                    ></TextArea>
                    <Input
                        style={{
                            border: '1.5px solid rgba(176, 194, 255, .6)',
                            borderRadius: '13px',
                        }}
                        placeholder={'Ссылка'}
                        maskType={String}
                        value={descr}
                        onChange={(e) => setDescr(e.target.value)}
                    />

                    {/* <ReactQuill
            value={descr}
            onChange={setDescr}
            style={{ height: "200px", color: "black" }}
            formats={formats}
            modules={modules}
          /> */}
                    {/* <div>
            <h1 style={{ marginTop: "50px", fontSize: "20px", padding: 0 }}>
              Предпросмотр:
            </h1>
            <hr style={{ marginTop: "5px" }} />
            <div className="previewText">descr</div>
          </div> */}
                    <div style={{ margin: '0 auto' }}>
                        <Button text={'Создать'} onClick={onSubmit} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNavigation;
