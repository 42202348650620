import './AddMod.scss';
import { Modal, Tabs } from 'antd';
import Input from '../../../../components/Input/Input';
import { Row, Col } from 'antd';
import Pl from '../../../../components/Pl/Pl';
import Button from '../../../../components/Button/Button';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import catService from '../../../../services/catService';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import AddModItem from '../addModItem/AddModItem';
import SaveIcon from '../../../../icons/SaveIcon/SaveIcon';
import { useEffect } from 'react';
import switchCrm from '../../../../funcs/switchCrm';
import checkDomain from '../../../../funcs/checkDomain';
import { checkIsBao } from '../../../../utils/checkIsBao';
const cs = new catService();

const AddMod = ({ visible, close, plateId, update }) => {
    const { token, settings } = useSelector((state) => state);
    const [mods, setMods] = useState([]);

    const [Title, setTitle] = useState('');
    const [TitleEn, setTitleEn] = useState('');
    const [TitleKz, setTitleKz] = useState('');

    const [modCreateModal, setModCreateModal] = useState(false);
    const [saveLoad, setSaveLoad] = useState(false);
    const [IIkoID, setIIkoID] = useState('');
    const [selected, setSelected] = useState(null);
    const [minAmount, setMinAmount] = useState('');
    const [maxAmount, setMaxAmount] = useState('');
    const [defaultAmount, setDefaultAmount] = useState('');
    const [freeAmount, setFreeAmount] = useState('');

    const [hidden, sethidden] = useState('0');

    const closeHandle = () => {
        setMods([]);
        setTitle('');
        setTitleEn('');
        setTitleKz('');
        setIIkoID('');
        setSelected(null);
        sethidden('0');
        setMinAmount('');
        setMaxAmount('');
        setDefaultAmount('');
        setFreeAmount('');
        close();
    };

    const onSave = () => {
        setSaveLoad(true);
        cs.addMod(token, {
            IIkoID,
            ItemID: plateId,
            Title,
            Title_en: checkIsBao() ? TitleEn : undefined,
            Title_kz: checkIsBao() ? TitleKz : undefined,
            Modificators: mods,
            hidden,
            minAmount,
            maxAmount,
            defaultAmount,
            freeAmount,
        })
            .then((res) => {
                update(res);
            })
            .finally((_) => {
                setSaveLoad(false);
                closeHandle();
            });
    };

    const addMod = () => {
        setModCreateModal(true);
    };

    const removeMod = (itemIndex) => {
        setMods((s) => s.filter((_, index) => index !== itemIndex));
        setModCreateModal(false);
        setSelected(null);
    };

    useEffect(() => {
        if (!modCreateModal) {
            setSelected(null);
        }
    }, [modCreateModal]);

    const editItem = (item, index) => {
        setSelected({
            ...item,
            PictureUrl: item?.Image || item.PictureUrl,
            index: index,
        });
        addMod();
    };

    const titleTabs = [
        {
            key: '1',
            label: 'Русский язык',
            children: (
                <Input
                    maskType={String}
                    shadow={true}
                    value={Title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={'Название группы'}
                />
            ),
        },
        {
            key: '2',
            label: 'Казахский язык',
            children: (
                <Input
                    maskType={String}
                    shadow={true}
                    value={TitleKz}
                    onChange={(e) => setTitleKz(e.target.value)}
                    placeholder={'Название группы на казахском языке'}
                />
            ),
        },
        {
            key: '3',
            label: 'Английский язык',
            children: (
                <Input
                    maskType={String}
                    shadow={true}
                    value={TitleEn}
                    onChange={(e) => setTitleEn(e.target.value)}
                    placeholder={'Название группы на английском языке'}
                />
            ),
        },
    ];

    return (
        <Modal className='Modal' width={650} open={visible} onCancel={closeHandle}>
            <AddModItem
                onDelete={removeMod}
                data={selected}
                update={setMods}
                visible={modCreateModal}
                close={() => setModCreateModal(false)}
            />
            <h2 className='Modal__head'>Добавить группу модификаторов</h2>
            <div className='Modal__form'>
                <div className='Modal__form_row'>
                    {checkIsBao() ? (
                        <Tabs
                            defaultActiveKey='1'
                            items={titleTabs}
                            onChange={() => {}}
                            style={{ width: '100%' }}
                        />
                    ) : (
                        titleTabs[0].children
                    )}
                    {/*<Input*/}
                    {/*    maskType={String}*/}
                    {/*    shadow={true}*/}
                    {/*    value={Title}*/}
                    {/*    onChange={(e) => setTitle(e.target.value)} */}
                    {/*    placeholder={'Название группы'}/>*/}
                </div>
                <div className='Modal__form_row'>
                    {switchCrm(
                        settings,
                        <Input
                            maskType={String}
                            shadow={true}
                            value={IIkoID}
                            onChange={(e) => setIIkoID(e.target.value)}
                            placeholder={'ID в Iiko'}
                        />,
                        <Input
                            maskType={String}
                            shadow={true}
                            value={IIkoID}
                            onChange={(e) => setIIkoID(e.target.value)}
                            placeholder={'ID в RKeeper'}
                        />,
                        <Input
                            maskType={String}
                            shadow={true}
                            value={IIkoID}
                            onChange={(e) => setIIkoID(e.target.value)}
                            placeholder={'ID в Bitrix'}
                        />,
                        <Input
                            maskType={String}
                            shadow={true}
                            value={IIkoID}
                            onChange={(e) => setIIkoID(e.target.value)}
                            placeholder={'ID в FrontPad'}
                        />
                    )}
                </div>
                <div className='Modal__form_row'>
                    <Input
                        maskType={String}
                        shadow={true}
                        value={minAmount}
                        onChange={(e) => setMinAmount(e.target.value)}
                        placeholder={'Минимальное кол-во'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        maskType={String}
                        shadow={true}
                        value={maxAmount}
                        onChange={(e) => setMaxAmount(e.target.value)}
                        placeholder={'Максимальное кол-во'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        maskType={String}
                        shadow={true}
                        value={defaultAmount}
                        onChange={(e) => setDefaultAmount(e.target.value)}
                        placeholder={'Кол-во по стандарту'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        maskType={String}
                        shadow={true}
                        value={freeAmount}
                        onChange={(e) => setFreeAmount(e.target.value)}
                        placeholder={'Бесплатное кол-во'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Checkbox
                        shadow={true}
                        checked={hidden == '1'}
                        text={'Скрытая группа'}
                        onChange={(e) => {
                            if (e.target.checked) {
                                sethidden('1');
                            } else sethidden('0');
                        }}
                        id={'isHiddenMod'}
                    />
                </div>
                <div className='AddMod'>
                    <h3 className='AddMod__head panel-label'>Список модификаторов</h3>
                    <div className='AddMod__body'>
                        <div className='AddMod__body_list'>
                            {mods && mods.length > 0
                                ? mods.map((item, index) => (
                                      <div
                                          className='AddMod__body_item active'
                                          key={index}
                                      >
                                          <div
                                              onClick={() => editItem(item, index)}
                                              className='AddMod__body_item_main panel'
                                          >
                                              <Row gutter={[20, 0]}>
                                                  <Col span={10}>
                                                      <div className='AddMod__body_item_name'>
                                                          {item.Name}
                                                      </div>
                                                  </Col>
                                                  <Col span={10}>
                                                      <div className='AddMod__body_item_value'>
                                                          {item.Price}
                                                          {checkDomain('₽', '₸')}
                                                      </div>
                                                  </Col>
                                                  {item?.Image || item.PictureUrl ? (
                                                      <Col span={4}>
                                                          <div
                                                              className={
                                                                  'AddMod__body_item_img'
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      'AddMod__body_item_img_el'
                                                                  }
                                                              >
                                                                  <img
                                                                      src={
                                                                          item?.Image ||
                                                                          item.PictureUrl
                                                                      }
                                                                      alt=''
                                                                  />
                                                              </div>
                                                          </div>
                                                      </Col>
                                                  ) : null}
                                              </Row>
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                    <Pl
                        shadow={true}
                        text={'Добавить модификатор'}
                        onClick={addMod}
                        style={{ backgroundColor: '#fff', marginBottom: 40 }}
                    />
                </div>
                <div className='Modal__form_action'>
                    <Button
                        load={saveLoad}
                        onClick={onSave}
                        disabled={mods.length == 0}
                        type={'button'}
                        before={<SaveIcon color={'#fff'} size={20} />}
                        justify={'flex-start'}
                        text={'Сохранить'}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AddMod;
