import './PayDeliveryPage.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import Pl from '../../components/Pl/Pl';
import { Modal, Select } from 'antd';
import { BsTrash } from 'react-icons/bs';
import Input from '../../components/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { id } from 'date-fns/locale';
import 'react-quill/dist/quill.snow.css';
import parse from 'html-react-parser';
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';
import { notification } from 'antd';
import { GridContextProvider, GridDropZone, GridItem, swap } from 'react-grid-drag';

Quill.register('modules/htmlEditButton', htmlEditButton);

const PayDeliveryPage = () => {
    const { token, city } = useSelector((state) => state);
    const navigate = useNavigate();
    const [isViewDeliveryModalOpen, setIsViewDeliveryModalOpen] = useState(false);
    const [isEditDeliveryModalOpen, setIsEditDeliveryModalOpen] = useState(false);
    const [isViewQaModalOpen, setIsViewQaModalOpen] = useState(false);
    const [isEditQaModalOpen, setIsEditQaModalOpen] = useState(false);
    const [isViewConditionsModalOpen, setIsViewConditionsModalOpen] = useState(false);
    const [isEditConditionsModalOpen, setIsEditConditionsModalOpen] = useState(false);
    const [localTitle, setLocalTitle] = useState('');
    const [localText, setLocalText] = useState(null);
    const [localBlock, setLocalBlock] = useState(null);
    const [pageContent, setPageContent] = useState([]);
    const [topContent, setTopContent] = useState([]);
    const [middleContent, setMiddleContent] = useState([]);
    const [bottomContent, setBottomContent] = useState([]);
    const [qaContent, setQaContent] = useState([]);
    const [conditionsContent, setConditionsContent] = useState([]);
    const [modalTitle, setModaltitle] = useState(null);
    const [modalText, setModalText] = useState(null);
    const [modalSubtext, setModalSubtext] = useState(null);
    const [modalId, setModalId] = useState(null);

    const handleSelectChange = () => {};

    const showViewDeliveryModal = (title, content, id, subtext) => {
        setModaltitle(title);
        setModalText(content);
        setModalId(id);
        setModalSubtext(subtext);
        setIsViewDeliveryModalOpen(true);
    };
    const handleViewDeliveryModalCancel = () => {
        setIsViewDeliveryModalOpen(false);
    };
    const showEditDeliveryModal = () => {
        setIsEditDeliveryModalOpen(true);
    };
    const handleEditDeliveryModalCancel = () => {
        setIsEditDeliveryModalOpen(false);
    };
    const showViewQaModal = (title, content, id) => {
        setModaltitle(title);
        // setModalText(content);
        const replaceNbspWithBr = (htmlString) => {
            return htmlString.replace(/(&nbsp;)+/g, '<br/>');
        };
        setModalText(replaceNbspWithBr(content));
        setModalId(id);
        setIsViewQaModalOpen(true);
    };
    const handleViewQaModalCancel = () => {
        setIsViewQaModalOpen(false);
    };
    const showEditQaModal = () => {
        setIsEditQaModalOpen(true);
    };
    const handleEditQaModalCancel = () => {
        setIsEditQaModalOpen(false);
    };
    const showViewConditionsModal = (title, content, id) => {
        setModaltitle(title);
        // setModalText(content);
        const replaceNbspWithBr = (htmlString) => {
            return htmlString.replace(/(&nbsp;)+/g, '<br/>');
        };
        setModalText(replaceNbspWithBr(content));
        setModalId(id);
        setIsViewConditionsModalOpen(true);
    };
    const handleViewConditionsModalCancel = () => {
        setIsViewConditionsModalOpen(false);
    };
    const showEditConditionsModal = () => {
        setIsEditConditionsModalOpen(true);
    };
    const handleEditConditionsModalCancel = () => {
        setIsEditConditionsModalOpen(false);
    };

    const getCards = async () => {
        try {
            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ page: 'ShippingAndPayment', cityId: city.id }),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            function filterObjects(start, end) {
                return responseData.content.ru.filter(
                    (obj) => obj.id >= start && obj.id <= end
                );
            }
            setTopContent(filterObjects(0, 4));
            setMiddleContent(filterObjects(5, 8));
            setBottomContent(filterObjects(9, 12));
            setQaContent(responseData.content.ru[13].qa);
            setConditionsContent(responseData.content.ru[13].conditions);
            // console.log(responseData.content.ru[13].qa)
            console.log(responseData.content.ru);
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    useEffect(() => {
        getCards();
    }, [city]);

    const postCard = async (id) => {
        try {
            const updatedTopContent = topContent.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        title: modalTitle,
                        text: modalText,
                        subtext: modalSubtext,
                    };
                }
                return item;
            });
            const updatedMiddleContent = middleContent.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        title: modalTitle,
                        text: modalText,
                    };
                }
                return item;
            });
            const updatedBottomContent = bottomContent.map((item) => {
                if (item.id === id) {
                    return {
                        ...item,
                        title: modalTitle,
                        text: modalText,
                    };
                }
                return item;
            });
            const requestBody = {
                page: 'ShippingAndPayment',
                cityId: city.id,
                content: {
                    ru: [
                        ...updatedTopContent,
                        ...updatedMiddleContent,
                        ...updatedBottomContent,
                        { qa: qaContent, conditions: conditionsContent },
                    ],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            handleEditDeliveryModalCancel();
            setModaltitle(modalTitle);
            setModalText(modalText);
            // setLocalDescr("");
            // setLocalBlock("");
            getCards();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const editQa = async (id) => {
        try {
            const updatedQaContent = qaContent.map((item) => {
                if (item.id === id) {
                    return { ...item, question: modalTitle, answer: modalText };
                }
                return item;
            });
            console.log(updatedQaContent);
            const requestBody = {
                page: 'ShippingAndPayment',
                cityId: city.id,
                content: {
                    ru: [
                        ...topContent,
                        ...middleContent,
                        ...bottomContent,
                        { qa: [...updatedQaContent], conditions: conditionsContent },
                    ],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Успешно отредактировано' });
            handleEditQaModalCancel();
            getCards();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const handleDeleteQa = async (id) => {
        try {
            const updatedQa = qaContent.filter((item) => item.id !== id);

            const requestBody = {
                page: 'ShippingAndPayment',
                cityId: city.id,
                content: {
                    ru: [
                        ...topContent,
                        ...middleContent,
                        ...bottomContent,
                        { qa: [...updatedQa], conditions: [...conditionsContent] },
                    ],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Вопрос успешно удалён' });
            handleViewQaModalCancel();
            getCards();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const editCondition = async (id) => {
        try {
            const updatedConditionsContent = conditionsContent.map((item) => {
                if (item.id === id) {
                    return { ...item, question: modalTitle, answer: modalText };
                }
                return item;
            });
            console.log(updatedConditionsContent);
            const requestBody = {
                page: 'ShippingAndPayment',
                cityId: city.id,
                content: {
                    ru: [
                        ...topContent,
                        ...middleContent,
                        ...bottomContent,
                        { qa: qaContent, conditions: updatedConditionsContent },
                    ],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Успешно отредактировано' });
            handleEditConditionsModalCancel();
            getCards();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const handleDeleteCondition = async (id) => {
        try {
            const updatedConditions = conditionsContent.filter((item) => item.id !== id);

            const requestBody = {
                page: 'ShippingAndPayment',
                cityId: city.id,
                content: {
                    ru: [
                        ...topContent,
                        ...middleContent,
                        ...bottomContent,
                        { qa: [...qaContent], conditions: [...updatedConditions] },
                    ],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Условие доставки успешно удалено' });
            handleViewConditionsModalCancel();
            getCards();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
        htmlEditButton: {},
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];

    const updateQaContent = async (swappedContent) => {
        try {
            const requestBody = {
                page: 'ShippingAndPayment',
                cityId: city.id,
                content: {
                    ru: [
                        ...topContent,
                        ...middleContent,
                        ...bottomContent,
                        { qa: swappedContent, conditions: conditionsContent },
                    ],
                },
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }
            const responseData = await response.json();
            getCards();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const handleQaOrderChange = (sourceId, sourceIndex, targetIndex, targetId) => {
        console.log(sourceIndex, targetIndex, sourceId, targetId);
        if (sourceIndex === qaContent.length) {
            return;
        } else {
            const nextState = swap(qaContent, sourceIndex, targetIndex);
            updateQaContent(nextState);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className='page'
        >
            <div className='pageBody'>
                <div className='DeliveryPage__body pageBody-content'>
                    <h4 className='DeliveryPage__title'>Блоки сверху:</h4>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                            gap: '25px',
                        }}
                    >
                        {topContent
                            ? topContent.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.title}
                                      onClick={() =>
                                          showViewDeliveryModal(
                                              item.title,
                                              item.text,
                                              item.id,
                                              item?.subtext
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                    </div>
                    <h4 className='DeliveryPage__title'>Блоки посередине:</h4>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                            gap: '25px',
                        }}
                    >
                        {middleContent
                            ? middleContent.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.title}
                                      onClick={() =>
                                          showViewDeliveryModal(
                                              item.title,
                                              item.text,
                                              item.id
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                    </div>
                    <h4 className='DeliveryPage__title'>Блоки снизу:</h4>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
                            gap: '25px',
                        }}
                    >
                        {bottomContent
                            ? bottomContent.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.title}
                                      onClick={() =>
                                          showViewDeliveryModal(
                                              item.title,
                                              item.text,
                                              item.id
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                    </div>

                    <div className='BonusesPage__bottom'>
                        <p className='BonusesPage__text'>Условия доставки: </p>
                        <Button
                            text={'Добавить'}
                            onClick={() => {
                                navigate('/pay-delivery/conditions');
                            }}
                        ></Button>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            gap: '30px',
                            marginTop: '20px',
                        }}
                    >
                        {conditionsContent
                            ? conditionsContent.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.question}
                                      onClick={() =>
                                          showViewConditionsModal(
                                              item.question,
                                              item.answer,
                                              item.id
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                    </div>
                    <div className='BonusesPage__bottom' style={{ marginBottom: '30px' }}>
                        <p className='BonusesPage__text'>Вопрос-ответ: </p>
                        <Button
                            text={'Добавить'}
                            onClick={() => {
                                navigate('/pay-delivery/create');
                            }}
                        ></Button>
                    </div>
                    <div style={{ display: 'block' }}>
                        <GridContextProvider onChange={handleQaOrderChange}>
                            <GridDropZone
                                boxesPerRow={4}
                                rowHeight={300}
                                // style={{ height: qaContent.length > 0 ? '300px' : '200px' }}
                            >
                                {qaContent?.map((c, i) => (
                                    <GridItem key={i}>
                                        <Pl
                                            style={{
                                                width: '95%',
                                                height: '200px',
                                                backgroundColor: '#fff',
                                                display: 'block',
                                                marginRight: '20px',
                                                pointerEvents: 'none',
                                            }}
                                            text={c.question}
                                            // onClick={() =>
                                            //   showViewQaModal(c.question, c.answer, c.id)
                                            // }
                                        ></Pl>
                                        <Button
                                            onClick={() =>
                                                showViewQaModal(
                                                    c.question,
                                                    c.answer,
                                                    c.id
                                                )
                                            }
                                            justify={'center'}
                                            styles={{ width: '95%', marginTop: '10px' }}
                                            text={'Просмотреть'}
                                        />
                                    </GridItem>
                                ))}
                            </GridDropZone>
                        </GridContextProvider>
                    </div>
                    <Modal
                        open={isViewConditionsModalOpen}
                        title={modalTitle}
                        onCancel={handleViewConditionsModalCancel}
                        footer={[]}
                        width={580}
                    >
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Вопрос</div>
                            <p className='DeliveryPage__modal-title'>
                                {modalTitle ? parse(modalTitle) : null}
                            </p>
                        </div>
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Ответ</div>
                            <p className='DeliveryPage__modal-descr'>
                                {modalText ? parse(modalText) : null}
                            </p>
                        </div>
                        <div className='VacanciesPage__modal-wrapper'>
                            <Button
                                key='back'
                                onClick={() => handleDeleteCondition(modalId)}
                                text={'Удалить'}
                                variant={'danger'}
                                before={<BsTrash />}
                            />
                            <Button
                                key='back'
                                onClick={showEditConditionsModal}
                                text={'Редактировать'}
                            />
                        </div>
                        <Modal
                            open={isEditConditionsModalOpen}
                            title='Редактировать'
                            onCancel={handleEditConditionsModalCancel}
                            footer={[]}
                            width={880}
                        >
                            <div className='VacanciesPage__item'>
                                <div className='VacanciesPage__item-label'>Заголовок</div>
                                <Input
                                    style={{
                                        border: '1.5px solid rgba(176, 194, 255, .6)',
                                        borderRadius: '13px',
                                    }}
                                    maskType={String}
                                    shadow
                                    onChange={(e) => {
                                        setModaltitle(e.target.value);
                                    }}
                                    value={modalTitle}
                                />
                            </div>
                            <div className='VacanciesPage__item-label'>Описание</div>
                            {/* <TextArea
                  className="vacanciesPage__textarea"
                  value={modalContent}
                  onChange={(e) => {setLocalDescr(e.target.value); setModalContent(e.target.value)}}
                  rows={4}
                ></TextArea> */}
                            <ReactQuill
                                value={modalText ? modalText : null}
                                onChange={(e) => {
                                    setModalText(e);
                                }}
                                style={{ height: '200px', color: 'black' }}
                                formats={formats}
                                modules={modules}
                            />
                            <div
                                style={{ marginTop: '80px' }}
                                className='VacanciesPage__modal-wrapper'
                            >
                                <Button
                                    key='back'
                                    onClick={handleEditConditionsModalCancel}
                                    text={'Отмена'}
                                    variant={'danger'}
                                />
                                <Button
                                    key='back'
                                    onClick={() => editCondition(modalId)}
                                    text={'Сохранить'}
                                />
                            </div>
                        </Modal>
                    </Modal>
                    <Modal
                        open={isViewQaModalOpen}
                        title={modalTitle}
                        onCancel={handleViewQaModalCancel}
                        footer={[]}
                        width={580}
                    >
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Вопрос</div>
                            <p className='DeliveryPage__modal-title'>
                                {modalTitle ? parse(modalTitle) : null}
                            </p>
                        </div>
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Ответ</div>
                            <p className='DeliveryPage__modal-descr'>
                                {modalText ? parse(modalText) : null}
                            </p>
                        </div>
                        <div className='VacanciesPage__modal-wrapper'>
                            <Button
                                key='back'
                                onClick={() => handleDeleteQa(modalId)}
                                text={'Удалить'}
                                variant={'danger'}
                                before={<BsTrash />}
                            />
                            <Button
                                key='back'
                                onClick={showEditQaModal}
                                text={'Редактировать'}
                            />
                        </div>
                        <Modal
                            open={isEditQaModalOpen}
                            title='Редактировать'
                            onCancel={handleEditQaModalCancel}
                            footer={[]}
                            width={880}
                        >
                            <div className='VacanciesPage__item'>
                                <div className='VacanciesPage__item-label'>Заголовок</div>
                                <Input
                                    style={{
                                        border: '1.5px solid rgba(176, 194, 255, .6)',
                                        borderRadius: '13px',
                                    }}
                                    maskType={String}
                                    shadow
                                    onChange={(e) => {
                                        setModaltitle(e.target.value);
                                    }}
                                    value={modalTitle}
                                />
                            </div>
                            <div className='VacanciesPage__item-label'>Описание</div>
                            {/* <TextArea
                  className="vacanciesPage__textarea"
                  value={modalContent}
                  onChange={(e) => {setLocalDescr(e.target.value); setModalContent(e.target.value)}}
                  rows={4}
                ></TextArea> */}
                            <ReactQuill
                                value={modalText ? modalText : null}
                                onChange={(e) => {
                                    setModalText(e);
                                }}
                                style={{ height: '200px', color: 'black' }}
                                formats={formats}
                                modules={modules}
                            />
                            <div
                                style={{ marginTop: '80px' }}
                                className='VacanciesPage__modal-wrapper'
                            >
                                <Button
                                    key='back'
                                    onClick={handleEditQaModalCancel}
                                    text={'Отмена'}
                                    variant={'danger'}
                                />
                                <Button
                                    key='back'
                                    onClick={() => editQa(modalId)}
                                    text={'Сохранить'}
                                />
                            </div>
                        </Modal>
                    </Modal>
                    <Modal
                        open={isViewDeliveryModalOpen}
                        title={modalTitle}
                        onCancel={handleViewDeliveryModalCancel}
                        footer={[]}
                        width={580}
                    >
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Заголовок</div>
                            <p className='DeliveryPage__modal-title'>{modalTitle}</p>
                        </div>
                        <div className='QAPage__item DeliveryPage__item'>
                            <div className='QAPage__item-label'>Текст</div>
                            <p className='DeliveryPage__modal-descr'>
                                {modalText ? parse(modalText) : null}
                            </p>
                        </div>
                        {modalId >= 0 && modalId <= 4 ? (
                            <div className='QAPage__item DeliveryPage__item'>
                                <div className='QAPage__item-label'>Текст 2</div>
                                <p className='DeliveryPage__modal-descr'>
                                    {modalSubtext ? parse(modalSubtext) : null}
                                </p>
                            </div>
                        ) : null}
                        <div className='DeliveryPage__modal-wrapper'>
                            {/* <Button
                key="back"
                onClick={handleViewDeliveryModalCancel}
                text={"Удалить"}
                variant={"danger"}
                before={<BsTrash />}
              /> */}
                            <Button
                                key='back'
                                onClick={showEditDeliveryModal}
                                text={'Редактировать'}
                            />
                        </div>
                        <Modal
                            open={isEditDeliveryModalOpen}
                            title='Редактировать'
                            onCancel={handleEditDeliveryModalCancel}
                            footer={[]}
                            width={680}
                        >
                            {/* <div className="DeliveryPage__item">
                <div className="DeliveryPage__item-label">Заголовок</div>
                <Input
                  style={{
                    border: "1.5px solid rgba(176, 194, 255, .6)",
                    borderRadius: "13px",
                  }}
                  maskType={String}
                  shadow
                  onChange={(e) => setLocalTitle(e.target.value)}
                  value={localTitle}
                />
              </div> */}
                            <div className='QAPage__item'>
                                <div className='QAPage__item-label'>Заголовок</div>
                                <Input
                                    style={{
                                        border: '1.5px solid rgba(176, 194, 255, .6)',
                                        borderRadius: '13px',
                                    }}
                                    maskType={String}
                                    shadow
                                    onChange={(e) => {
                                        setModaltitle(e.target.value);
                                        setLocalTitle(e.target.value);
                                    }}
                                    value={modalTitle}
                                />
                            </div>
                            <div className='DeliveryPage__item-label'>Текст</div>
                            {/* <TextArea
                  className="DeliveryPage__textarea"
                  value={modalText}
                  onChange={(e) => {
                    setLocalText(e.target.value);
                    setModalText(e.target.value);
                  }}
                  rows={4}
                ></TextArea> */}
                            <ReactQuill
                                value={modalText}
                                onChange={setModalText}
                                style={{ height: '200px', color: 'black' }}
                                formats={formats}
                                modules={modules}
                            />
                            {modalId >= 0 && modalId <= 4 ? (
                                <>
                                    <div
                                        className='DeliveryPage__item-label'
                                        style={{ marginTop: '50px' }}
                                    >
                                        Текст 2
                                    </div>
                                    <ReactQuill
                                        value={modalSubtext}
                                        onChange={setModalSubtext}
                                        style={{ height: '200px', color: 'black' }}
                                        formats={formats}
                                        modules={modules}
                                    />
                                </>
                            ) : null}
                            <div className='DeliveryPage__modal-wrapper'>
                                <Button
                                    key='back'
                                    onClick={handleEditDeliveryModalCancel}
                                    text={'Отмена'}
                                    variant={'danger'}
                                />
                                <Button
                                    key='back'
                                    text={'Сохранить'}
                                    onClick={() => postCard(modalId)}
                                />
                            </div>
                        </Modal>
                    </Modal>
                </div>
            </div>
        </motion.div>
    );
};

export default PayDeliveryPage;
