import "./OrdersInfo.scss";
import Input from "../../../../components/Input/Input";
import InputSelect from "../../../../components/InputSelect/InputSelect";
import checkDomain from "../../../../funcs/checkDomain";
import Button from "../../../../components/Button/Button";
import { useSelector } from "react-redux";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useEffect, useState } from "react";
// import robotoFont from '../../../../assets/fonts/roboto-regular.ttf';
import roboto from "./exportFont";
import { Modal } from "antd";

jsPDF.API.events.push([
  "addFonts",
  function () {
    this.addFileToVFS("roboto-regular.ttf", roboto);
    this.addFont("roboto-regular.ttf", "Roboto", "normal");
  },
]);

// var callAddFont = function () {
// this.addFileToVFS('roboto-regular-normal.ttf', font);
// this.addFont('roboto-regular-normal.ttf', 'roboto', 'normal');
// };
// jsPDF.API.events.push(['addFonts', callAddFont])

const list = [
  { ID: "id", value: "ID" },
  { ID: "name", value: "Имя" },
  { ID: "cost", value: "Цена" },
  { ID: "phone", value: "Телефон" },
];

const OrdersInfo = ({
  total,
  price,
  onSearch,
  value,
  setValue,
  Field,
  setField,
}) => {
  const { token } = useSelector((state) => state);
  const [ordersExport, setOrdersExport] = useState([]);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [exportFrom, setExportFrom] = useState("");
  const [exportTo, setExportTo] = useState("");

  const exportOrders = async (exportFrom, exportTo) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST ?? window.location.origin}/NewAdminPanel/orderUpdate/exportOrders`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ BETWEEN: `${exportFrom}/${exportTo}` }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      console.log(responseData);
      exportToPDF(responseData);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  const exportToPDF = (orders) => {
    const doc = new jsPDF();

    doc.setFont("Roboto");

    // Определите заголовки таблицы
    const tableColumn = ["Название", "Количество", "Масса"];

    // Подготовьте данные для таблицы
    const tableRows = orders.map((order) => [
      order.Name,
      order.Count,
      `${order.mass_num} ${order.mass_type}`,
    ]);

    // Добавьте таблицу в документ
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      styles: { font: "Roboto" },
    });

    // Сохраните PDF
    doc.save("orders.pdf");
  };

  return (
    <div className="OrdersInfo">
      <div className="OrdersInfo__item OrdersInfo__item-input">
        <Input
          maskType={String}
          placeholder={"Поиск"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div className="OrdersInfo__item">
        <InputSelect
          list={list}
          // defaultValue={fVal}
          showSearch={false}
          value={Field}
          onSelect={setField}
        />
      </div>

      <div className="OrdersInfo__item">Всего заказов: {total}</div>
      <div className="OrdersInfo__item">
        На сумму: {price} {checkDomain("₽", "₸")}
      </div>
      {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'http://localhost:3000' ?
      <div className="OrdersInfo__item">
        <Button
          onClick={() => {
            setIsExportModalOpen(true);
          }}
          text={"Экспорт заказов"}
          styles={{ width: "100%" }}
        />
      </div> : null}

      <Modal
        open={isExportModalOpen}
        title="Экспорт заказов"
        onCancel={() => setIsExportModalOpen(false)}
        footer={[]}
        width={580}
      >
        <div className="QAPage__item" style={{ columnGap: "40px" }}>
          <Input
            style={{
              border: "1.5px solid rgba(176, 194, 255, .6)",
              borderRadius: "13px",
            }}
            maskType={Number}
            shadow
            onChange={(e) => {
              setExportFrom(e.target.value);
            }}
            value={exportFrom}
            placeholder={"С какого заказа"}
          />
          <Input
            style={{
              border: "1.5px solid rgba(176, 194, 255, .6)",
              borderRadius: "13px",
            }}
            maskType={Number}
            shadow
            onChange={(e) => {
              setExportTo(e.target.value);
            }}
            value={exportTo}
            placeholder={"По какой заказ"}
          />
        </div>

        <div
          style={{ marginTop: "30px" }}
          className="ArticlePage__modal-wrapper"
        >
          <Button
            key="back"
            onClick={() => setIsExportModalOpen(false)}
            text={"Отмена"}
            variant={"danger"}
          />
          <Button
            key="back"
            onClick={() => exportOrders(exportFrom, exportTo)}
            text={"Экспорт"}
          />
        </div>
      </Modal>
    </div>
  );
};

export default OrdersInfo;
