import { useEffect, useState } from "react";
import dataService from '../../services/dataService'
import { useSelector } from "react-redux";
import Button from "../../components/Button/Button";
import { message } from "antd";


const AdminPanel = () => {
    const dtService = new dataService();
    const {token} = useSelector(state => state)
    const [data, setData] = useState()
    const [btnLoading, setBtnLoading] = useState(false)

    useEffect(() => {
        if(token) {
            dtService.getFrontStatus(token).then(res => {
                setData(res)
            })
        }
    }, [token])

    const updateHandler = async () => {
        setBtnLoading(true)
        if(token) {
            dtService.updateFrontStatus(token).then(res => {
                if (res.error) {
                    message.error(res.error)
                }
                setTimeout(() => {
                    window.location.reload()
                    setBtnLoading(false)
                }, 2000)
            })
        }
    }

    return data && ( 
    <div style={{ paddingTop: "110px", paddingLeft: "20px", paddingBottom: '40px', width: "100%" }}>
        <p>{data.status.status}</p>
        <p>{data.status.date}</p>
        <Button
            onClick={updateHandler}
            text={'Обновить'}
            load={btnLoading}
            justify={'flex-start'}
        />
    </div>
     );
}
 
export default AdminPanel;