export const tokenUpdate = (token) => ({ type: 'TOKEN', token: token });
export const settingsUpdate = (settings) => ({ type: 'SETTINGS', settings });
export const userUpdate = (user) => ({ type: 'USER', user });
export const catalogUpdate = (catalog) => ({ type: 'CATALOG', catalog });
export const handleSidebarOpen = (sidebarOpen) => ({ type: 'SIDEBAR', sidebarOpen });
export const updateBrands = (brands) => ({ type: 'BRANDS', brands });
export const updateBackFunc = (backFunc) => ({ type: 'BACK_FUNC', backFunc });
export const updateSettingsFront = (settingsFront) => ({
    type: 'SETTING_FRONT',
    settingsFront,
});
export const updateCity = (citiesUpdate) => ({ type: 'CITIES', citiesUpdate });
