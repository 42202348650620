import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import './Partnership.scss';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import Pl from '../../components/Pl/Pl';
import { Modal } from 'antd';
import { BsTrash } from 'react-icons/bs';
import Input from '../../components/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
import parse from 'html-react-parser';
import 'react-quill/dist/quill.snow.css';
import ReactQuill, { Quill } from 'react-quill';
import { htmlEditButton } from 'quill-html-edit-button';

Quill.register('modules/htmlEditButton', htmlEditButton);

const Partnership = () => {
    const { token, city } = useSelector((state) => state);
    const navigate = useNavigate();
    const [isViewVacancyModalOpen, setIsViewVacancyModalOpen] = useState(false);
    const [isEditVacancyModalOpen, setIsEditVacancyModalOpen] = useState(false);
    const [localTitle, setLocalTitle] = useState('');
    const [localDescr, setLocalDescr] = useState('');
    const [modalTitle, setModaltitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [modalId, setModalId] = useState(null);
    const [pageContent, setPageContent] = useState([]);
    const showViewVacancyModal = (title, content, id) => {
        setModaltitle(title);
        const replaceNbspWithBr = (htmlString) => {
            return htmlString.replace(/(&nbsp;)+/g, '<br/>');
        };
        console.log(content);
        setModalContent(replaceNbspWithBr(content));
        console.log(replaceNbspWithBr(content));
        setModalId(id);
        setIsViewVacancyModalOpen(true);
    };
    const handleViewVacancyModalCancel = () => {
        setIsViewVacancyModalOpen(false);
    };
    const showEditVacancyModal = () => {
        setIsEditVacancyModalOpen(true);
    };
    const handleEditVacancyModalCancel = () => {
        setIsEditVacancyModalOpen(false);
    };

    const getVacancies = async () => {
        try {
            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ page: 'Partnership', cityId: city.id }),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            setPageContent(responseData.content.ru);
            console.log(responseData.content.ru);
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    useEffect(() => {
        getVacancies();
    }, []);

    const editVacancy = async (id) => {
        try {
            const updatedPageContent = pageContent.map((item) => {
                if (item.id === id) {
                    return { ...item, title: modalTitle, descr: modalContent };
                }
                return item;
            });
            const requestBody = {
                page: 'Partnership',
                content: {
                    ru: updatedPageContent,
                },
                cityId: city.id,
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Успешно отредактировано' });
            handleEditVacancyModalCancel();
            setLocalTitle('');
            setLocalDescr('');
            getVacancies();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const handleDeleteVacancy = async (id) => {
        try {
            const updatedPageContent = pageContent.filter((item) => item.id !== id);

            const requestBody = {
                page: 'Partnership',
                content: {
                    ru: updatedPageContent,
                },
                cityId: city.id,
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Успешно удалено' });
            handleViewVacancyModalCancel();
            getVacancies();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
        htmlEditButton: {},
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className='page'
        >
            <div className='pageBody'>
                <div className='VacanciesPage__body pageBody-content'>
                    <div className='VacanciesPage__header'>
                        <Button
                            styles={{ marginBottom: '30px' }}
                            text={'Добавить'}
                            onClick={() => {
                                navigate('/partnership/create');
                            }}
                        ></Button>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                            gap: '30px',
                        }}
                    >
                        {pageContent
                            ? pageContent.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '60px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.title}
                                      onClick={() =>
                                          showViewVacancyModal(
                                              item.title,
                                              item.descr,
                                              item.id
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                    </div>
                    <Modal
                        open={isViewVacancyModalOpen}
                        title={modalTitle}
                        onCancel={handleViewVacancyModalCancel}
                        footer={[]}
                        width={580}
                    >
                        <div className='ArticlePage__modal-text'></div>
                        <p className='VacanciesPage__modal-descr'>
                            {modalContent ? parse(modalContent) : null}
                        </p>
                        <div className='VacanciesPage__modal-wrapper'>
                            <Button
                                key='back'
                                onClick={() => handleDeleteVacancy(modalId)}
                                text={'Удалить'}
                                variant={'danger'}
                                before={<BsTrash />}
                            />
                            <Button
                                key='back'
                                onClick={showEditVacancyModal}
                                text={'Редактировать'}
                            />
                        </div>
                        <Modal
                            open={isEditVacancyModalOpen}
                            title='Редактировать'
                            onCancel={handleEditVacancyModalCancel}
                            footer={[]}
                            width={880}
                        >
                            <div className='VacanciesPage__item'>
                                <div className='VacanciesPage__item-label'>Заголовок</div>
                                <Input
                                    style={{
                                        border: '1.5px solid rgba(176, 194, 255, .6)',
                                        borderRadius: '13px',
                                    }}
                                    maskType={String}
                                    shadow
                                    onChange={(e) => {
                                        setLocalTitle(e.target.value);
                                        setModaltitle(e.target.value);
                                    }}
                                    value={modalTitle}
                                />
                            </div>
                            <div className='VacanciesPage__item-label'>Описание</div>
                            {/* <TextArea
                  className="vacanciesPage__textarea"
                  value={modalContent}
                  onChange={(e) => {setLocalDescr(e.target.value); setModalContent(e.target.value)}}
                  rows={4}
                ></TextArea> */}
                            <ReactQuill
                                value={modalContent ? modalContent : null}
                                onChange={(e) => {
                                    setModalContent(e);
                                    setLocalDescr(e);
                                }}
                                style={{ height: '200px', color: 'black' }}
                                formats={formats}
                                modules={modules}
                            />
                            <div
                                style={{ marginTop: '80px' }}
                                className='VacanciesPage__modal-wrapper'
                            >
                                <Button
                                    key='back'
                                    onClick={handleEditVacancyModalCancel}
                                    text={'Отмена'}
                                    variant={'danger'}
                                />
                                <Button
                                    key='back'
                                    onClick={() => editVacancy(modalId)}
                                    text={'Сохранить'}
                                />
                            </div>
                        </Modal>
                    </Modal>
                </div>
            </div>
        </motion.div>
    );
};

export default Partnership;
