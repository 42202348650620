import './CatItem.scss';
import Button from '../../../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const CatItem = ({
    AllowedDeliveryTypes,
    CanOverwriteByIIko,
    Disabled,
    HiddenInOrganisations,
    ID,
    IIkoID,
    ItemOrder,
    Name,
    Link,
    HideInApp,
    selectEdit,
    PictureUrl,
    HideChangeButton,
    styles,
    platePicture,
    platePictureNull,
    ...rest
}) => {
    const nav = useNavigate();
    const [tm, setTm] = useState(false);

    const clickHandle = () => {
        setTimeout(() => {
            setTm(true);
        }, 200);
    };

    const checkClick = () => {
        if (tm) {
            setTm(false);
            return;
        } else {
            nav(Link);
        }
    };

    return (
        <div className='CatItem' style={styles}>
            <div
                className='CatItem__main'
                onMouseUp={checkClick}
                onMouseDown={clickHandle}
            >
                <p
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        padding: '14px',
                        color: 'red',
                    }}
                >
                    {platePicture}/{platePictureNull}
                </p>
                <div className='CatItem__main_name'>
                    {PictureUrl ? (
                        <img className='CatItem__img' src={PictureUrl} alt='' />
                    ) : null}
                    <p className='CatItem__name'>{Name}</p>
                </div>
            </div>
            {HideChangeButton ? null : (
                <div className='CatItem__action'>
                    <Button
                        onClick={() =>
                            selectEdit({
                                ID,
                                IIkoID,
                                HideInApp,
                                ItemOrder,
                                Name,
                                HiddenInOrganisations,
                                Disabled,
                                CanOverwriteByIIko,
                                AllowedDeliveryTypes,
                                ...rest,
                            })
                        }
                        justify={'center'}
                        styles={{ width: '100%' }}
                        text={'Изменить'}
                    />
                </div>
            )}
        </div>
    );
};

export default CatItem;
