import './HeaderProfile.scss';
import logo_bao from '../../assets/img/logo-bao.png';
import sellLogo from '../../assets/img/sell-logo.svg';
import { BsChevronLeft, BsChevronCompactDown, BsArrowRightShort } from 'react-icons/bs';
import { Dropdown } from 'antd';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Bc from '../Bc/Bc';
import CitiesMenu from '../CitiesMenu/CitiesMenu';
import dataService from '../../services/dataService';
import { useDispatch } from 'react-redux';
import { updateCity } from '../../store/actions';

const HeaderProfile = () => {
    const { sidebarOpen, user, backFunc } = useSelector((state) => state);
    const [back, setBack] = useState(false);
    const [links, setLinks] = useState([]);
    const nav = useNavigate();
    const { city } = useSelector((s) => s);
    const dtService = new dataService();
    const { token } = useSelector((s) => s);
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = useState(false);
    const LOCAL_STORAGE = window.localStorage;

    const url = useMemo(() => {
        return new URLSearchParams(window.location.search);
    }, [window.location.search]);

    useEffect(() => {
        if (token) {
            dtService.getCities(token).then((res) => {
                if (!!LOCAL_STORAGE.getItem('gs-city')) {
                    return;
                } else {
                    dispatch(updateCity(res[0]));
                }
            });
        }
    }, [token]);

    const updateHead = (path) => {
        if (path == '/') {
            return (
                <div className='HeaderProfile__main_nav_head_item'>
                    <Link to={'/organizations'}>Организации</Link>
                </div>
            );
        }
        if (path.includes('organizations')) {
            return (
                <div className='HeaderProfile__main_nav_head_item'>
                    <Link to={'/organizations'}>Организации</Link>
                </div>
            );
        }
        if (path.includes('catalog')) {
            return (
                <div className='HeaderProfile__main_nav_head_item'>
                    <Link to={'/catalog'}>Каталог</Link>
                </div>
            );
        }
        if (path.includes('stories')) {
            return 'Сториз';
        }
        if (path.includes('clients')) {
            return 'Клиенты';
        }
        if (path.includes('orders')) {
            return 'Заказы';
        }
        if (path.includes('statistic')) {
            return 'Статистика';
        }
        if (path.includes('basket')) {
            return 'Корзина';
        }
        if (path.includes('integr')) {
            return 'Интеграции';
        }
        if (path.includes('allsettings')) {
            return 'Все настройки';
        }
        if (path.includes('trash')) {
            return 'Удаленные обьекты';
        }
    };

    useEffect(() => {
        setLinks(url.getAll('p'));
    }, [url]);

    useEffect(() => {
        if (links?.length > 0) {
            setBack(true);
        } else {
            setBack(false);
        }
    }, [links]);

    return (
        <header className='HeaderProfile'>
            <div className='HeaderProfile__in'>
                <div className={'HeaderProfile__logo' + (!sidebarOpen ? ' hide ' : '')}>
                    {/* {window.location.origin === 'https://ibazar.gscore.ru' || window.location.origin === 'https://ident.gscore.ru' ? null : <img src={checkDomain(logo, logo_bao)} alt="Logo" />} */}
                    {window.location.origin === 'https://ibazar.gscore.ru' ||
                    window.location.origin === 'https://bao.gscore.ru' ? null : (
                        <img
                            style={{ width: '70%', paddingLeft: '20%' }}
                            src={sellLogo}
                            alt='Logo'
                        />
                    )}
                    {window.location.origin === 'https://bao.gscore.ru' && (
                        <img
                            style={{ width: '70%', paddingLeft: '20%' }}
                            src={logo_bao}
                            alt='Logo'
                        />
                    )}
                </div>
                <div className='HeaderProfile__main'>
                    {/* breadcrumbs */}
                    <div className='HeaderProfile__main_nav'>
                        <div className='HeaderProfile__main_nav_head'>
                            {back ? (
                                backFunc ? (
                                    <div
                                        className='HeaderProfile__main_nav_head_back'
                                        onClick={backFunc}
                                    >
                                        <BsChevronLeft />
                                    </div>
                                ) : (
                                    <div
                                        className='HeaderProfile__main_nav_head_back'
                                        onClick={() => nav(-1)}
                                    >
                                        <BsChevronLeft />
                                    </div>
                                )
                            ) : null}
                            {/* {
                                updateHead(loc?.pathname)
                            } */}
                            {links && links.length > 0
                                ? links.map((item, index) => (
                                      <div
                                          key={index}
                                          className={'HeaderProfile__main_nav_head_item'}
                                      >
                                          {index != 0 ? (
                                              <BsArrowRightShort className='HeaderProfile__main_nav_head_item_icon' />
                                          ) : null}
                                          <div className='HeaderProfile__main_nav_head_item_label'>
                                              {item}
                                          </div>
                                      </div>
                                  ))
                                : null}
                        </div>
                    </div>
                    <div className='HeaderProfile__main_rightBlock'>
                        <Dropdown
                            placement='bottom'
                            trigger={['click']}
                            onOpenChange={setIsOpen}
                            open={isOpen}
                            overlay={
                                <CitiesMenu
                                    onSelect={(value) => {
                                        dispatch(updateCity(value));
                                        LOCAL_STORAGE.setItem(
                                            'gs-city',
                                            JSON.stringify(value)
                                        );
                                        setIsOpen(false);
                                    }}
                                />
                            }
                        >
                            <div className='HeaderProfile__main_user'>
                                <span className='HeaderProfile__main_user_name'>
                                    {city?.title}
                                </span>
                                <span className='HeaderProfile__main_user_icon'>
                                    <BsChevronCompactDown />
                                </span>
                            </div>
                        </Dropdown>
                        <Bc />

                        <Dropdown
                            placement='bottom'
                            trigger={['click']}
                            overlay={<ProfileMenu />}
                        >
                            <div className='HeaderProfile__main_user'>
                                <span className='HeaderProfile__main_user_name'>
                                    {user?.Name}
                                </span>
                                <span className='HeaderProfile__main_user_icon'>
                                    <BsChevronCompactDown />
                                </span>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderProfile;
