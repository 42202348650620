import './PromotionsPage.scss';
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import Pl from '../../components/Pl/Pl';
import { Modal } from 'antd';
import { BsTrash } from 'react-icons/bs';
import Input from '../../components/Input/Input';
import TextArea from 'antd/lib/input/TextArea';
import { useSelector } from 'react-redux';
import { notification } from 'antd';

const PromotionsPage = () => {
    const { token, city } = useSelector((state) => state);
    const navigate = useNavigate();
    const [isViewPromotionsModalOpen, setIsViewPromotionsModalOpen] = useState(false);
    const [isEditPromotionsModalOpen, setIsEditPromotionsModalOpen] = useState(false);
    const [localTitle, setLocalTitle] = useState('');
    const [localDescr, setLocalDescr] = useState('');
    const [modalTitle, setModaltitle] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const [modalId, setModalId] = useState(null);
    const [pageContent, setPageContent] = useState([]);
    const showViewPromotionsModal = (title, content, id) => {
        setModaltitle(title);
        setModalContent(content);
        setModalId(id);
        setIsViewPromotionsModalOpen(true);
    };
    const handleViewPromotionsModalCancel = () => {
        setIsViewPromotionsModalOpen(false);
    };
    const showEditPromotionsModal = () => {
        setIsEditPromotionsModalOpen(true);
    };
    const handleEditPromotionsModalCancel = () => {
        setIsEditPromotionsModalOpen(false);
    };

    const getPromotions = async () => {
        try {
            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ page: 'Promotions', cityId: city.id }),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            setPageContent(responseData.content.ru);
            console.log(responseData.content.ru);
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    useEffect(() => {
        getPromotions();
    }, []);

    const editPromotions = async (id) => {
        try {
            const updatedPageContent = pageContent.map((item) => {
                if (item.id === id) {
                    return { ...item, title: localTitle, descr: localDescr };
                }
                return item;
            });
            const requestBody = {
                page: 'Promotions',
                content: {
                    ru: updatedPageContent,
                },
                cityId: city.id,
            };

            const response = await fetch(
                `${
                    process.env.REACT_APP_HOST ?? window.location.origin
                }/NewAdminPanel/pages/getPages`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                throw new Error('Ошибка HTTP: ' + response.status);
            }

            const responseData = await response.json();
            notification.success({ message: 'Акция успешно отредактирована' });
            handleEditPromotionsModalCancel();
            setLocalTitle('');
            setLocalDescr('');
            getPromotions();
        } catch (error) {
            console.error('Ошибка при получении страницы:', error);
        }
    };

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ opacity: 0 }}
            className='page'
        >
            <div className='pageBody'>
                <div className='PromotionsPage__body pageBody-content'>
                    <Button
                        styles={{ width: '60%', margin: '0 auto 30px' }}
                        text={'Добавить акцию'}
                        onClick={() => {
                            navigate('/promotions/create');
                        }}
                    ></Button>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr 1fr',
                            gap: '30px',
                        }}
                    >
                        {pageContent
                            ? pageContent.map((item) => (
                                  <Pl
                                      style={{
                                          width: '100%',
                                          height: '200px',
                                          backgroundColor: '#fff',
                                      }}
                                      text={item.title}
                                      onClick={() =>
                                          showViewPromotionsModal(
                                              item.title,
                                              item.descr,
                                              item.id
                                          )
                                      }
                                  ></Pl>
                              ))
                            : null}
                    </div>
                    <Modal
                        open={isViewPromotionsModalOpen}
                        title={modalTitle}
                        onCancel={handleViewPromotionsModalCancel}
                        footer={[]}
                        width={580}
                    >
                        <p className='PromotionsPage__modal-descr'>{modalContent}</p>
                        <div className='PromotionsPage__modal-wrapper'>
                            {/* <Button
                key="back"
                onClick={handleViewPromotionsModalCancel}
                text={"Удалить"}
                variant={"danger"}
                before={<BsTrash />}
              /> */}
                            <Button
                                key='back'
                                onClick={showEditPromotionsModal}
                                text={'Редактировать'}
                            />
                        </div>
                        <Modal
                            open={isEditPromotionsModalOpen}
                            title='Редактировать'
                            onCancel={handleEditPromotionsModalCancel}
                            footer={[]}
                            width={680}
                        >
                            <div className='PromotionsPage__item'>
                                <div className='PromotionsPage__item-label'>
                                    Заголовок акции
                                </div>
                                <Input
                                    style={{
                                        border: '1.5px solid rgba(176, 194, 255, .6)',
                                        borderRadius: '13px',
                                    }}
                                    maskType={String}
                                    shadow
                                    onChange={(e) => {
                                        setModaltitle(e.target.value);
                                        setLocalTitle(e.target.value);
                                    }}
                                    value={modalTitle}
                                />
                            </div>
                            <div className='PromotionsPage__item'>
                                <div className='PromotionsPage__item-label'>
                                    Описание акции
                                </div>
                                <TextArea
                                    className='PromotionsPage__textarea'
                                    value={modalContent}
                                    onChange={(e) => {
                                        setModalContent(e.target.value);
                                        setLocalDescr(e.target.value);
                                    }}
                                    rows={4}
                                ></TextArea>
                            </div>
                            <div className='PromotionsPage__modal-wrapper'>
                                <Button
                                    key='back'
                                    onClick={handleEditPromotionsModalCancel}
                                    text={'Отмена'}
                                    variant={'danger'}
                                />
                                <Button
                                    key='back'
                                    onClick={() => editPromotions(modalId)}
                                    text={'Сохранить'}
                                />
                            </div>
                        </Modal>
                    </Modal>
                </div>
            </div>
        </motion.div>
    );
};

export default PromotionsPage;
