import './NoDomain.scss';
import {motion} from 'framer-motion';
import pageEnterAnimProps from '../../funcs/pageEnterAnimProps';
import sellLogo from '../../assets/img/sell-logo.svg'
const NoDomainPage = () => {

    return (
        <motion.div 
            {...pageEnterAnimProps}
            className="NoDomain page">  
            <main className="Main">
                <div className="NoDomain__in">
                    <div className="NoDomain__body">
                    {/* <img src={sellLogo} width={300} alt="Logo" /> */}
                        <h1 style={{fontWeight: '700', fontSize: '126px'}}>4<span style={{color:'#1890ff'}}>0</span>4</h1>
                        <h2 className="NoDomain__body_title">Домен не доступен</h2>
                    </div>
                </div>
                
            </main>
        </motion.div>
    )
}

export default NoDomainPage;