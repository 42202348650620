import { Modal } from 'antd';
import Input from '../../../../components/Input/Input';
import { DatePicker } from 'antd';
import { Row, Col } from 'antd';
import Button from '../../../../components/Button/Button';
import { BsTrash } from 'react-icons/bs';
import DropCollapse from '../../../../components/DropCollapse/DropCollapse';
import { useState } from 'react';
import { useEffect } from 'react';
import SaveIcon from '../../../../icons/SaveIcon/SaveIcon';
import IntegrIcon from '../../../../icons/IntegrIcon/IntegrIcon';
import InputSelect from '../../../../components/InputSelect/InputSelect';
import checkDomain from '../../../../funcs/checkDomain';
import catService from '../../../../services/catService';
import { useSelector } from 'react-redux';
import orgService from '../../../../services/orgService';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import TagComponent from '../../../../components/TagComponent/TagComponent';
import { useDebounce } from '@uidotdev/usehooks';

const cs = new catService();
const org = new orgService();

const promoTypes = [
    { value: 'Скидка (%)', id: '2' },
    { value: `Скидка (${checkDomain('₽', '₸')})`, id: '1' },
    { value: 'Подарок', id: '3' },
    { value: 'Повышенный кэшбэк', id: '4' },
    { value: 'Скидка на доставку', id: '5' },
    { value: 'Акционный подарок', id: '6' },
];

const BasketAddPromo = ({ visible, close, data, list, setList, selectList }) => {
    const { token } = useSelector((state) => state);
    const [Disabled, setDisabled] = useState('0');
    const [GiftName, setGiftName] = useState('');
    const [ID, setID] = useState('0');
    const [MinCartPrice, setMinCartPrice] = useState('');
    const [Promocode, setPromocode] = useState('');
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [groupNumber, setGroupNumber] = useState(0);
    const [PromocodeType, setPromocodeType] = useState('0');
    const [SalePercent, setSalePercent] = useState('0');
    const [SalePrice, setSalePrice] = useState('0');
    // const [item, setItem] = useState(null);
    const [MaxUses, setMaxUses] = useState('');
    const [dataStart, setDataStart] = useState('');
    const [dataEnd, setDataEnd] = useState('');
    const [prodList, setProdList] = useState([]);
    const [catsList, setCatsList] = useState([]);
    const [active, setActive] = useState(false);
    const [asCoupon, setAsCoupon] = useState(false);
    const [orgsList, setOrgsList] = useState([]);
    const [orgId, setOrgId] = useState(0);
    const [discountsList, setDiscountsList] = useState([]);
    const [discountId, setDiscountId] = useState('');
    const [deliveryType1, setDeliveryType1] = useState(false);
    const [deliveryType2, setDeliveryType2] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [user, setUser] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const debouncedPhone = useDebounce(phoneValue, 400);
    const { city } = useSelector((state) => state);

    useEffect(() => {
        cs.getCatsNames(token, { elements: 'categories', cityId: city.id }).then(
            (res) => {
                const arr = res.map((i) => {
                    return {
                        ...i,
                        value: i?.ID,
                        label: i.Name,
                    };
                });
                setCatsList(arr);
            }
        );
        cs.getCatsNames(token, { elements: 'plates', cityId: city.id }).then((res) => {
            const arr = res.map((i) => {
                return {
                    ...i,
                    value: i?.ID,
                    label: i.Name,
                };
            });
            setProdList(arr);
        });
        org.getOrgs(token, { cityId: city?.id ?? 0 }).then((res) => {
            const arr = res.map((i) => {
                return {
                    ...i,
                    value: i?.ID,
                    label: i.Name,
                };
            });
            setOrgsList([{ value: 0, label: 'Не выбрано' }, ...arr]);
            cs.getDiscountIiko(token, { organisationId: res?.[0]?.ID }).then((res) => {
                let arr = [];
                if (res?.discounts?.[0]?.items?.length > 0) {
                    arr = res?.discounts?.[0]?.items?.map((i, index) => {
                        return {
                            ...i,
                            value: i.id,
                            label: i.name,
                            ID: index,
                        };
                    });
                } else {
                    arr = [];
                }
                setDiscountsList([{ value: 0, label: 'Не выбрано' }, ...arr]);
            });
        });
    }, [city]);

    const handleClose = () => {
        setDisabled('0');
        setGiftName('');
        setID('0');
        setMinCartPrice('');
        setPromocode('');
        setPromocodeType('1');
        setSalePrice('0');
        setSalePercent('0');
        setMaxUses('0');
        setPlatesTags([]);
        setCategoriesTags([]);
        close();
    };

    const onSave = (item) => {
        if (item.ID != '0') {
            const r = list;
            const rm = r.splice(
                r.findIndex((i) => i.ID == item.ID),
                1,
                item
            );
            setList([...r]);
        } else {
            setList((state) => [...state, item]);
        }
        handleClose();
    };

    const onCopy = (item) => {
        setList((state) => [...state, item]);

        handleClose();
    };

    const onDelete = (item) => {
        if (item.ID != '0') {
            const r = list;
            const rm = r.splice(
                r.findIndex((i) => i.ID == item.ID),
                1
            );
            setList([...r]);
        } else {
            const r = list;
            const rm = r.splice(item.index, 1);
            setList([...r]);
        }
        handleClose();
    };

    const onEdit = (item) => {
        const r = list;
        const rm = r.splice(
            r.findIndex((i) => i.index == item.index),
            1,
            item
        );
        setList([...r]);
        handleClose();
    };

    const selectPromoType = (value) => {
        if (value === 'Подарок') {
            setPlatesTags([]);
            setCategoriesTags([]);
        } else {
            setGiftsTags([]);
        }
        setPromocodeType(promoTypes.find((item) => item.value == value).id);
    };

    const selectOrg = (value) => {
        setOrgId(value.ID);
        cs.getDiscountIiko(token, { organisationId: value.ID }).then((res) => {
            let arr = [];
            if (res?.discounts?.[0]?.items?.length > 0) {
                arr = res?.discounts?.[0]?.items?.map((i, index) => {
                    return {
                        ...i,
                        value: i.id,
                        label: i.name,
                        ID: index,
                    };
                });
            } else {
                arr = [];
            }
            setDiscountsList([{ value: 0, label: 'Не выбрано' }, ...arr]);
        });
    };
    const selectDiscount = (value) => {
        setDiscountId(value.id);
    };

    const [categoriesTags, setCategoriesTags] = useState([]);

    const selectCat = (value) => {
        let tags = [...categoriesTags];
        if (!tags.some((tag) => tag.id === value.ID)) {
            tags.push({ id: value.ID, name: value.Name });
        }

        setCategoriesTags(tags);
        setPlatesTags([]);
    };

    const deleteCategoryHandler = (id) => {
        setCategoriesTags((prev) =>
            prev.filter((item) => Number(item.id) !== Number(id))
        );
    };

    const [platesTags, setPlatesTags] = useState([]);

    const selectPlate = (value) => {
        let tags = [...platesTags];
        if (!tags.some((tag) => tag.id === value.ID)) {
            tags.push({ id: value.ID, name: value.Name });
        }

        setPlatesTags(tags);
        setCategoriesTags([]);
    };

    const deletePlateHandler = (id) => {
        setPlatesTags((prev) => prev.filter((item) => Number(item.id) !== Number(id)));
    };

    const [giftsTags, setGiftsTags] = useState([]);

    const selectGifts = (value) => {
        let tags = [...giftsTags];
        if (!tags.some((tag) => tag.id === value.ID)) {
            tags.push({ id: value.ID, name: value.Name });
        }

        setGiftsTags(tags);
    };

    const deleteGiftHandler = (id) => {
        setGiftsTags((prev) => prev.filter((item) => Number(item.id) !== Number(id)));
    };

    const selectedTypePromoInput = (type) => {
        switch (type) {
            case '1':
                return (
                    <Input
                        shadow
                        value={SalePrice?.toString()}
                        maskType={String}
                        onChange={(e) => setSalePrice(e.target.value)}
                        placeholder={`Скидка (${checkDomain('₽', '₸')})`}
                    />
                );
            case '2':
                return (
                    <Input
                        maskType={String}
                        shadow
                        value={SalePercent?.toString()}
                        onChange={(e) => setSalePercent(e.target.value)}
                        placeholder={'Скидка (%)'}
                    />
                );
            case '3':
                return (
                    <>
                        <InputSelect
                            value={null}
                            onSelect={selectGifts}
                            list={prodList}
                        />
                        <div style={{ marginTop: '8px' }}>
                            <TagComponent
                                tagsList={giftsTags}
                                setTagsList={setGiftsTags}
                                deleteHandler={deleteGiftHandler}
                            />
                        </div>
                    </>
                );
            case '4':
                return (
                    <Input
                        maskType={String}
                        shadow
                        value={SalePercent?.toString()}
                        onChange={(e) => setSalePercent(e.target.value)}
                        placeholder={'Скидка (%)'}
                    />
                );
            case '5':
                return (
                    <Input
                        maskType={String}
                        shadow
                        value={SalePercent?.toString()}
                        onChange={(e) => setSalePercent(e.target.value)}
                        placeholder={'Скидка (%)'}
                    />
                );
            case '6':
                return (
                    <>
                        <InputSelect
                            value={null}
                            onSelect={selectGifts}
                            list={prodList}
                        />
                        <div style={{ marginTop: '8px' }}>
                            <TagComponent
                                tagsList={giftsTags}
                                setTagsList={setGiftsTags}
                                deleteHandler={deleteGiftHandler}
                            />
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    const handlePhoneChange = (value) => {
        setPhoneValue(value);
    };
    const handleSelectUser = (value) => {
        if (value.value === 0) {
            setUser(null);
        } else {
            setUser(value);
        }
    };

    useEffect(() => {
        if (debouncedPhone?.length > 4) {
            cs.getUsers(token, { phone: debouncedPhone }).then((res) => {
                if (!res.message) {
                    const arr = res?.map((i) => {
                        return {
                            ...i,
                            value: i?.ID,
                            Name: i.Phone,
                            label: i.Phone,
                        };
                    });
                    setUsersList([{ value: 0, label: 'Не выбрано' }, ...arr]);
                }
            });
        }
    }, [debouncedPhone]);

    useEffect(() => {
        if (data !== null) {
            setDisabled(data?.Disabled);
            setGiftName(data?.GiftName);
            setID(data?.ID);
            setMinCartPrice(data?.MinCartPrice);
            setPromocode(data?.Promocode);
            setPromocodeType(String(data?.PromocodeType));
            setSalePrice(data?.SalePrice);
            setSalePercent(data?.SalePercent);
            setMaxUses(data?.MaxUses);
            setDataStart(data?.dataStart);
            setDataEnd(data?.dataEnd);
            setUser(data?.user_id);
            switch (String(data?.deliveryTypes)) {
                case '0':
                    setDeliveryType1(true);
                    break;
                case '1':
                    setDeliveryType2(true);
                    break;
                case '2':
                    setDeliveryType1(true);
                    setDeliveryType2(true);
                    break;
                default:
                    setDeliveryType1(false);
                    setDeliveryType2(false);
                    break;
            }

            setActive(data?.active);
            setAsCoupon(data?.asCoupon);

            let platesTagsArr = [];
            data?.plateId?.split(',')?.forEach((item) => {
                prodList.forEach((cat) => {
                    if (Number(item) === Number(cat.ID)) {
                        platesTagsArr.push({ name: cat.Name, id: item });
                    }
                });
            });
            setPlatesTags(platesTagsArr);

            let giftsTagsArr = [];
            data?.gift_ids?.split(',')?.forEach((item) => {
                prodList.forEach((cat) => {
                    if (Number(item) === Number(cat.ID)) {
                        giftsTagsArr.push({ name: cat.Name, id: item });
                    }
                });
            });
            setGiftsTags(giftsTagsArr);

            let catsTagsArr = [];
            data?.categoryId?.split(',')?.forEach((item) => {
                catsList.forEach((cat) => {
                    if (Number(item) === Number(cat.ID)) {
                        catsTagsArr.push({ name: cat.Name, id: item });
                    }
                });
            });
            setCategoriesTags(catsTagsArr);

            setGroupNumber(data?.groupId);
            setOrgId(data?.organisationId);
            setDescription(data?.description);
            setShortDescription(data?.shortDescription);
            setDiscountsList(data?.discountIikoId);
        } else {
            setDisabled('0');
            setGiftName('');
            setID('0');
            setMinCartPrice('');
            setPromocode('');
            setPromocodeType('1');
            setSalePrice('0');
            setSalePercent('0');
            setMaxUses('');
            setDataEnd('');
            setDataStart('');
            setDiscountsList(0);
            setDiscountsList([]);
            setOrgId(0);
            setCategoriesTags([]);
            setPlatesTags([]);
            setOrgsList([]);
            setActive(false);
        }
    }, [data]);

    return (
        <Modal width={600} className='Modal' open={visible} onCancel={handleClose}>
            <h2 className='Modal__head'>Добавить промокод</h2>
            <form className='Modal__form'>
                <div className='Modal__form_row'>
                    <Input
                        shadow
                        value={Promocode?.toString()}
                        onChange={(e) => setPromocode(e.target.value)}
                        maskType={String}
                        placeholder={'Промокод'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        shadow
                        value={description?.toString() || null}
                        onChange={(e) => setDescription(e.target.value)}
                        maskType={String}
                        placeholder={'Описание'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        shadow
                        value={shortDescription?.toString() || null}
                        onChange={(e) => setShortDescription(e.target.value)}
                        maskType={String}
                        placeholder={'корткое описание'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        shadow
                        value={String(groupNumber) || null}
                        onChange={(e) => setGroupNumber(e.target.value)}
                        maskType={Number}
                        placeholder={'Номер группы'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        shadow
                        value={MinCartPrice?.toString()}
                        onChange={(e) => setMinCartPrice(e.target.value)}
                        placeholder={'Минимальная сумма заказа'}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Input
                        shadow
                        value={MaxUses?.toString()}
                        onChange={(e) => setMaxUses(e.target.value)}
                        placeholder={'Максимальное количество использований'}
                    />
                </div>

                <div className='Modal__form_row'>
                    <div style={{ fontWeight: 600, color: '#989898', marginBottom: 10 }}>
                        Тип промокода
                    </div>
                    <div className='Modal__form_row'>
                        <DropCollapse
                            justify={'justifyLeft'}
                            shadow={true}
                            list={promoTypes}
                            value={
                                promoTypes.find((item) => item.id == PromocodeType)?.value
                            }
                            selectItem={selectPromoType}
                            beforeIcon
                        />
                    </div>
                </div>

                {PromocodeType?.toString() !== '3' && (
                    <>
                        <div className='Modal__form_row'>
                            <div
                                style={{
                                    fontWeight: 600,
                                    color: '#989898',
                                    marginBottom: 10,
                                }}
                            >
                                Промокод на категорию
                            </div>
                            <InputSelect
                                type={String}
                                value={null}
                                list={catsList}
                                placeholder='Выберите список категорий'
                                onSelect={selectCat}
                            />
                            <div style={{ marginTop: '8px' }}>
                                <TagComponent
                                    tagsList={categoriesTags}
                                    setTagsList={setCategoriesTags}
                                    deleteHandler={deleteCategoryHandler}
                                />
                            </div>
                        </div>

                        <div className='Modal__form_row'>
                            <div
                                style={{
                                    fontWeight: 600,
                                    color: '#989898',
                                    marginBottom: 10,
                                }}
                            >
                                Промокод на товар
                            </div>
                            <InputSelect
                                type={String}
                                list={prodList}
                                value={null}
                                placeholder='Введите список товаров'
                                onSelect={selectPlate}
                            />
                            <div style={{ marginTop: '8px' }}>
                                <TagComponent
                                    tagsList={platesTags}
                                    setTagsList={setPlatesTags}
                                    deleteHandler={deletePlateHandler}
                                />
                            </div>
                        </div>
                    </>
                )}

                <div className='Modal__form_row'>
                    <DatePicker
                        showTime
                        onChange={(value, dateString) => {
                            setDataStart(dateString);
                        }}
                        placeholder={dataStart || 'Дата начала'}
                        style={{
                            borderRadius: '10px',
                            padding: '10px 10px',
                            marginRight: '15px',
                        }}
                    />
                    <DatePicker
                        showTime
                        onChange={(value, dateString) => {
                            setDataEnd(dateString);
                        }}
                        placeholder={dataEnd || 'Дата конца'}
                        style={{ borderRadius: '10px', padding: '10px 10px' }}
                    />
                </div>
                <div className='Modal__form_row'>
                    <Checkbox
                        shadow
                        checked={active}
                        onChange={(e) => {
                            setActive(e.target.checked);
                        }}
                        text={'Активен'}
                        id='promo-active'
                    />
                </div>
                <div className='Modal__form_row'>
                    <Checkbox
                        shadow
                        checked={asCoupon}
                        onChange={(e) => {
                            setAsCoupon(e.target.checked);
                        }}
                        text={'Портировать как купон'}
                        id='promo-cupon'
                    />
                </div>
                <div className='Modal__form_row'>
                    <div
                        style={{
                            fontWeight: 600,
                            color: '#989898',
                            marginBottom: 10,
                        }}
                    >
                        Организация
                    </div>
                    <InputSelect
                        value={orgId || null}
                        placeholder='Выберите организацию'
                        onSelect={selectOrg}
                        list={orgsList}
                        defaultValue={orgId}
                    />
                </div>
                {PromocodeType?.toString() !== '3' && (
                    <>
                        <div className='Modal__form_row'>
                            <div
                                style={{
                                    fontWeight: 600,
                                    color: '#989898',
                                    marginBottom: 10,
                                }}
                            >
                                id скидки в iiko
                            </div>
                            <InputSelect
                                value={discountId || null}
                                placeholder='id скидки в iiko'
                                onSelect={selectDiscount}
                                list={discountsList?.length > 0 ? discountsList : []}
                            />
                        </div>
                    </>
                )}
                <div className='Modal__form_row'>
                    {selectedTypePromoInput(PromocodeType)}
                </div>
                <div className='Modal__form_row'>
                    <Checkbox
                        shadow
                        checked={deliveryType1}
                        onChange={(e) => {
                            setDeliveryType1(e.target.checked);
                        }}
                        text={'Доставка'}
                        id='deliveryType-1'
                    />
                </div>
                <div className='Modal__form_row'>
                    <Checkbox
                        shadow
                        checked={deliveryType2}
                        onChange={(e) => {
                            setDeliveryType2(e.target.checked);
                        }}
                        text={'Самовывоз'}
                        id='deliveryType-2'
                    />
                </div>
                <div className='Modal__form_row'>
                    <div
                        style={{
                            fontWeight: 600,
                            color: '#989898',
                            marginBottom: 10,
                        }}
                    >
                        Телефон гостя (мин. 5 цифр)
                    </div>
                    <InputSelect
                        value={user || null}
                        placeholder='Пользователи'
                        onSelect={handleSelectUser}
                        onInput={handlePhoneChange}
                        showSearch
                        list={usersList?.length > 0 ? usersList : []}
                    />
                </div>

                <div className='Modal__form_action'>
                    <Row gutter={[15, 15]}>
                        <Col span={24}>
                            <Button
                                type={'button'}
                                before={<SaveIcon color={'#fff'} size={16} />}
                                justify={'flex-start'}
                                text={'Сохранить'}
                                disabled={!Promocode}
                                onClick={() => {
                                    if (data?.ID != '0') {
                                        let deliveryTypes = '3';
                                        if (deliveryType1 && deliveryType2) {
                                            deliveryTypes = '2';
                                        } else if (deliveryType1) {
                                            deliveryTypes = '0';
                                        } else if (deliveryType2) {
                                            deliveryTypes = '1';
                                        }
                                        onSave({
                                            Disabled,
                                            GiftName,
                                            MinCartPrice: MinCartPrice ?? 0,
                                            ID,
                                            Promocode,
                                            PromocodeType,
                                            SalePercent:
                                                PromocodeType === '1' ||
                                                PromocodeType === '5'
                                                    ? '0'
                                                    : SalePercent,
                                            SalePrice:
                                                PromocodeType === '2' ? '0' : SalePrice,
                                            MaxUses,
                                            dataStart,
                                            dataEnd,
                                            user_id: user?.ID ?? '',
                                            cashbackPercent: SalePercent,
                                            plateId:
                                                PromocodeType === '3'
                                                    ? '0'
                                                    : platesTags?.length > 0
                                                    ? platesTags
                                                          ?.map((item) => item.id)
                                                          .join(',')
                                                    : '0',
                                            gift_ids:
                                                PromocodeType === '3'
                                                    ? giftsTags?.length > 0
                                                        ? giftsTags
                                                              ?.map((item) => item.id)
                                                              .join(',')
                                                        : '0'
                                                    : '0',
                                            categoryId:
                                                PromocodeType === '3'
                                                    ? '0'
                                                    : categoriesTags?.length > 0
                                                    ? categoriesTags
                                                          ?.map((item) => item.id)
                                                          .join(',')
                                                    : '0',
                                            groupId: groupNumber,
                                            description,
                                            shortDescription,
                                            active,
                                            asCoupon,
                                            discountIikoId: discountId,
                                            organisationId: orgId,
                                            deliveryTypes,
                                        });
                                    } else {
                                        let deliveryTypes = '3';
                                        if (deliveryType1 && deliveryType2) {
                                            deliveryTypes = '2';
                                        } else if (deliveryType1) {
                                            deliveryTypes = '0';
                                        } else if (deliveryType2) {
                                            deliveryTypes = '1';
                                        }

                                        onEdit({
                                            Disabled,
                                            GiftName,
                                            MinCartPrice: MinCartPrice ?? 0,
                                            ID,
                                            Promocode,
                                            PromocodeType,
                                            SalePercent:
                                                PromocodeType === '1' ||
                                                PromocodeType === '5'
                                                    ? '0'
                                                    : SalePercent,
                                            SalePrice:
                                                PromocodeType === '2' ? '0' : SalePrice,
                                            cashbackPercent: SalePercent,
                                            MaxUses,
                                            dataStart,
                                            dataEnd,
                                            user_id: user?.ID ?? '',
                                            plateId:
                                                PromocodeType === '3'
                                                    ? '0'
                                                    : platesTags?.length > 0
                                                    ? platesTags
                                                          ?.map((item) => item.id)
                                                          .join(',')
                                                    : '0',
                                            gift_ids:
                                                PromocodeType === '3'
                                                    ? giftsTags?.length > 0
                                                        ? giftsTags
                                                              ?.map((item) => item.id)
                                                              .join(',')
                                                        : '0'
                                                    : '0',
                                            categoryId:
                                                PromocodeType === '3'
                                                    ? '0'
                                                    : categoriesTags?.length > 0
                                                    ? categoriesTags
                                                          ?.map((item) => item.id)
                                                          .join(',')
                                                    : '0',
                                            groupId: groupNumber,
                                            description,
                                            shortDescription,
                                            active,
                                            asCoupon,
                                            discountIikoId: discountId,
                                            organisationId: orgId,
                                            deliveryTypes,
                                        });
                                    }
                                }}
                            />
                        </Col>
                        <Col span={24}>
                            <Button
                                type={'button'}
                                before={<IntegrIcon color={'#fff'} size={16} />}
                                justify={'flex-start'}
                                text={'Копировать промокод'}
                                disabled={!Promocode}
                                onClick={() => {
                                    let deliveryTypes = '3';
                                    if (deliveryType1 && deliveryType2) {
                                        deliveryTypes = '2';
                                    } else if (deliveryType1) {
                                        deliveryTypes = '0';
                                    } else if (deliveryType2) {
                                        deliveryTypes = '1';
                                    }
                                    onCopy({
                                        Disabled,
                                        GiftName,
                                        MinCartPrice: MinCartPrice ?? 0,
                                        ID,
                                        Promocode: Promocode + ' (copy)',
                                        PromocodeType,
                                        SalePercent:
                                            PromocodeType === '1' || PromocodeType === '5'
                                                ? '0'
                                                : SalePercent,
                                        SalePrice:
                                            PromocodeType === '2' ? '0' : SalePrice,
                                        MaxUses,
                                        dataStart,
                                        dataEnd,
                                        user_id: user?.ID ?? '',
                                        cashbackPercent: SalePercent,
                                        plateId:
                                            PromocodeType === '3'
                                                ? '0'
                                                : platesTags?.length > 0
                                                ? platesTags
                                                      ?.map((item) => item.id)
                                                      .join(',')
                                                : '0',
                                        gift_ids:
                                            PromocodeType === '3'
                                                ? giftsTags?.length > 0
                                                    ? giftsTags
                                                          ?.map((item) => item.id)
                                                          .join(',')
                                                    : '0'
                                                : '0',
                                        categoryId:
                                            PromocodeType === '3'
                                                ? '0'
                                                : categoriesTags?.length > 0
                                                ? categoriesTags
                                                      ?.map((item) => item.id)
                                                      .join(',')
                                                : '0',
                                        groupId: groupNumber,
                                        description,
                                        shortDescription,
                                        active,
                                        asCoupon,
                                        discountIikoId: discountId,
                                        organisationId: orgId,
                                        deliveryTypes,
                                    });
                                }}
                            />
                        </Col>
                        {data != null ? (
                            <Col span={24}>
                                <Button
                                    variant={'danger'}
                                    type={'button'}
                                    before={<BsTrash />}
                                    justify={'flex-start'}
                                    onClick={() => {
                                        let deliveryTypes = '3';
                                        if (deliveryType1 && deliveryType2) {
                                            deliveryTypes = '2';
                                        } else if (deliveryType1) {
                                            deliveryTypes = '0';
                                        } else if (deliveryType2) {
                                            deliveryTypes = '1';
                                        }

                                        onDelete({
                                            Disabled,
                                            GiftName,
                                            MinCartPrice: MinCartPrice ?? 0,
                                            ID,
                                            Promocode,
                                            PromocodeType,
                                            SalePercent,
                                            SalePrice,
                                            index: data.index,
                                            cashbackPercent: SalePercent,
                                            MaxUses,
                                            dataStart,
                                            dataEnd,
                                            user_id: user?.ID ?? '',
                                            plateId: platesTags,
                                            gift_ids:
                                                PromocodeType === '3'
                                                    ? giftsTags?.length > 0
                                                        ? giftsTags
                                                              ?.map((item) => item.id)
                                                              .join(',')
                                                        : '0'
                                                    : '0',
                                            categoryId: categoriesTags,
                                            groupId: groupNumber,
                                            description,
                                            shortDescription,
                                            active,
                                            asCoupon,
                                            discountIikoId: discountId,
                                            organisationId: orgId,
                                            deliveryTypes,
                                        });
                                    }}
                                    text={'Удалить'}
                                />
                            </Col>
                        ) : null}
                    </Row>
                </div>
            </form>
        </Modal>
    );
};

export default BasketAddPromo;
