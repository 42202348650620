import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
//pages
import AuthPage from '../pages/auth/AuthPage';
import OrgsPage from '../pages/orgs/orgs/OrgsPage';
import OrgsCreatePage from '../pages/orgs/orgsCreate/OrgsCreatePage';
import Notfound from '../pages/notfound/Notfound';
import CatalogPage from '../pages/catalog/catalog/CatalogPage';
import CatalogCategoryPage from '../pages/catalog/catalogCategory/CatalogCategoryPage';
import CreatePlatePage from '../pages/catalog/createPlate/CreatePlatePage';
import StoriesPage from '../pages/stories/StoriesPage';
import ClientsPage from '../pages/clients/ClientsPage';
import AppOrdersPage from '../pages/orders/AppOrdersPage';
import SiteOrdersPage from '../pages/orders/SiteOrdersPage';
import AppRevsPage from '../pages/revs/AppRevsPage';
import SiteRevsPage from '../pages/revs/SiteRevsPage';
import OrdersPage from '../pages/orders/OrdersPage';
import StatPage from '../pages/stat/StatPage';
import BasketPage from '../pages/basket/BasketPage';
import IntegrPage from '../pages/integr/IntegrPage';
import SettingsPage from '../pages/settings/SettingsPage';
import CheckAuth from '../hoc/ChekAuth';
import EditPlatePage from '../pages/catalog/createPlate/EditPlatePage';
import Sidebar from '../components/Sidebar/Sidebar';
import HeaderProfile from '../components/HeaderProfile/HeaderProfile';
import Header from '../components/Header/Header';
import { useLocation } from 'react-router-dom';
import OrgsNewPage from '../pages/orgs/orgsCreate/OrgsNewPage';
import { useEffect, useState } from 'react';
import EditPlateNew from '../pages/catalog/createPlate/EditPlateNew';
import RatesetPage from '../pages/rateset/RatesetPage';
import RevsPage from '../pages/revs/RevsPage';
import ReservPage from '../pages/reserv/ReservPage';
import TrashPage from '../pages/trash/TrashPage';
import checkDomain from '../funcs/checkDomain';
import CheckRole from '../hoc/CheckRole';
import StoriesPageYm from '../pages/stories-yam/StoriesPage';
import AddClientPage from '../pages/clients/AddClient';
import { RootPage as SitesModule } from '../modules/Sites/containers/RootPage';
import checkDom from '../funcs/checkDom';
import VacanciesPage from '../pages/vacancies/VacanciesPage';
import AddVacancyPage from '../pages/vacancies/AddVacancyPage';
import QAPage from '../pages/q-a/QAPage';
import AddQAPage from '../pages/q-a/AddQAPage';
import ArticlesPage from '../pages/articles/ArticlesPage';
import AddArticlePage from '../pages/articles/AddArticlePage';
import PromotionsPage from '../pages/promotions/PromotionsPage';
import AddPromotionPage from '../pages/promotions/AddPromotionPage';
import PayDeliveryPage from '../pages/PayDelivery/PayDeliveryPage';
import AddPayDeliveryPage from '../pages/PayDelivery/AddPayDeliveryPage';
import Bonuses from '../pages/bonuses/Bonuses';
import AddBonuses from '../pages/bonuses/AddBonuses';
import Partnership from '../pages/partnership/Partnership';
import AddPartnership from '../pages/partnership/AddPartnership';
import Banners from '../pages/banners/Banners';
import NewsPage from '../pages/news/NewsPage';
import NavigationPage from '../pages/navigation/NavigationPage';
import AddNavigationPage from '../pages/navigation/AddNavigationPage';
import AddNewsPage from '../pages/news/AddNewsPage';
import DeliveryRules from '../pages/deliveryRule/DeliveryRules';
import AddConditions from '../pages/PayDelivery/AddConditions';
import CatalogHits from '../pages/catalogHits/CatalogHits';
import PlateHits from '../pages/plateHits/PlateHits';
import Accessibility from '../pages/accessibility/Accessibility';
import Users from '../pages/users/Users';
import Loyalty from '../pages/loyalty/Loyalty';
import AddLoyalty from '../pages/loyalty/AddLoyalty';
import { useSelector } from 'react-redux';
import anService from '../services/anService';
import AdminPanel from '../pages/admin-panel/admin-panel';
import { useDispatch } from 'react-redux';
import { updateSettingsFront } from '../store/actions';
import NoDomainPage from '../pages/noDomain/NoDomain';

const App = () => {
    const loc = useLocation();
    const dispatch = useDispatch();
    const { token, settingsFront } = useSelector((s) => s);
    const anl = new anService();
    const [clientRateType, setClientRateType] = useState(null);

    useEffect(() => {
        checkDomain();
        // if(token) {
        //     anl.getSettings(token).then(res => {
        //         console.log('res', res)
        //     })
        // }
    }, []);
    useEffect(() => {
        if (token) {
            anl.getSettingsFront(token).then((res) => {
                dispatch(updateSettingsFront(res));
            });
        }
    }, [token]);

    return (
        <>
            <Helmet>
                {window?.location?.origin === 'https://bao.gscore.ru' && (
                    <link rel='icon' href={window?.location.origin + `/logo-bao.png`} />
                )}
                {window?.location?.origin !== 'https://bao.gscore.ru' && (
                    <link rel='icon' href={window.location.origin + '/logo.png'} />
                )}
            </Helmet>
            {loc.pathname == '/auth' || loc.pathname == '/no-domain' ? (
                <Header />
            ) : (
                <HeaderProfile />
            )}
            {loc.pathname != '/auth' && loc.pathname != '/no-domain' ? (
                <Sidebar updateCat />
            ) : null}
            <Routes>
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/organizations'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.ReservPage && (
                    <Route
                        path='/reservations'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <ReservPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <CatalogPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.Loyalty && (
                    <Route
                        path='/loyalty'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <Loyalty />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.Loyalty && (
                    <Route
                        path='/add-loyalty'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <AddLoyalty />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}

                {settingsFront?.CatalogHits && (
                    <Route
                        path='/categories-hits'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <CatalogHits />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.PlateHits && (
                    <Route
                        path='/plates-hits'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <PlateHits />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                <Route path='/site/*' element={<SitesModule />} />

                {settingsFront?.StatPage && (
                    <Route
                        path='/statistic'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <StatPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.BasketPage && (
                    <Route
                        path='/basket'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <BasketPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.IntegrPage && (
                    <Route
                        path='/integr'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <IntegrPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {/* {settingsFront?.Users && (
                    <Route
                        path='/admin-users'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <Users />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )} */}
                {settingsFront?.SettingsPage && (
                    <Route
                        path='/allsettings'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <SettingsPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId/createPlate'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <CreatePlatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId/:subcategoryId/createPlate'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <CreatePlatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId/editPlate/:plateId'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <EditPlatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId/editPlate/:plateId/now'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <EditPlateNew />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId/:subcategoryId/editPlate/:plateId'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <EditPlatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId/:subcategoryId/editPlate/:plateId/now'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <EditPlateNew />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <CatalogCategoryPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.CatalogPage && (
                    <Route
                        path='/catalog/:categoryId/:subcategoryId'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <CatalogCategoryPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/organizations/:brandId'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/organizations/:brandId/create'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsCreatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/organizations/:brandId/:orgId'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsCreatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/organizations/:brandId/:orgId/now'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsNewPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/organizations/create'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsCreatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrgsPage && (
                    <Route
                        path='/organizations/edit'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <OrgsCreatePage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.RatesetPage && (
                    <Route
                        path='/ratingSettings'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <RatesetPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}

                {settingsFront?.TrashPage && (
                    <Route
                        path='/trash'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <TrashPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}

                {settingsFront?.AppRevsPage && (
                    <Route
                        path='/revs-app'
                        element={
                            <CheckAuth>
                                <AppRevsPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.SiteRevsPage && (
                    <Route
                        path='/revs-site'
                        element={
                            <CheckAuth>
                                <SiteRevsPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.RevsPage && (
                    <Route
                        path='/revs'
                        element={
                            <CheckAuth>
                                <RevsPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.ClientsPage && (
                    <Route
                        path='/clients'
                        element={
                            <CheckAuth>
                                <ClientsPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.ClientsPage && (
                    <Route
                        path='/clients/create'
                        element={
                            <CheckAuth>
                                <AddClientPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.AppOrdersPage && (
                    <Route
                        path='/orders-app'
                        element={
                            <CheckAuth>
                                <AppOrdersPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.SiteOrdersPage && (
                    <Route
                        path='/orders-site'
                        element={
                            <CheckAuth>
                                <SiteOrdersPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.VacanciesPage && (
                    <Route
                        path='/vacancies'
                        element={
                            <CheckAuth>
                                <VacanciesPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.VacanciesPage && (
                    <Route
                        path='/vacancies/create'
                        element={
                            <CheckAuth>
                                <AddVacancyPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.ArticlesPage && (
                    <Route
                        path='/articles'
                        element={
                            <CheckAuth>
                                <ArticlesPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.ArticlesPage && (
                    <Route
                        path='/articles/create'
                        element={
                            <CheckAuth>
                                <AddArticlePage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.PromotionsPage && (
                    <Route
                        path='/promotions'
                        element={
                            <CheckAuth>
                                <PromotionsPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.PromotionsPage && (
                    <Route
                        path='/promotions/create'
                        element={
                            <CheckAuth>
                                <AddPromotionPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.PayDeliveryPage && (
                    <Route
                        path='/pay-delivery'
                        element={
                            <CheckAuth>
                                <PayDeliveryPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.PayDeliveryPage && (
                    <Route
                        path='/pay-delivery/create'
                        element={
                            <CheckAuth>
                                <AddPayDeliveryPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.PayDeliveryPage && (
                    <Route
                        path='/pay-delivery/conditions'
                        element={
                            <CheckAuth>
                                <AddConditions />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.Bonuses && (
                    <Route
                        path='/bonuses-program'
                        element={
                            <CheckAuth>
                                <Bonuses />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.Bonuses && (
                    <Route
                        path='/bonuses-program/create'
                        element={
                            <CheckAuth>
                                <AddBonuses />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.Partnership && (
                    <Route
                        path='/partnership'
                        element={
                            <CheckAuth>
                                <Partnership />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.Partnership && (
                    <Route
                        path='/partnership/create'
                        element={
                            <CheckAuth>
                                <AddPartnership />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.NewsPage && (
                    <Route
                        path='/news'
                        element={
                            <CheckAuth>
                                <NewsPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.NewsPage && (
                    <Route
                        path='/news/create'
                        element={
                            <CheckAuth>
                                <AddNewsPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.NavigationPage && (
                    <Route
                        path='/navigation'
                        element={
                            <CheckAuth>
                                <NavigationPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.NavigationPage && (
                    <Route
                        path='/navigation/create'
                        element={
                            <CheckAuth>
                                <AddNavigationPage />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.DeliveryRules && (
                    <Route
                        path='/delivery-rules'
                        element={
                            <CheckAuth>
                                <DeliveryRules />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.Accessibility && (
                    <Route
                        path='/accessibility'
                        element={
                            <CheckAuth>
                                <Accessibility />
                            </CheckAuth>
                        }
                    />
                )}
                {(settingsFront?.BannersSquare || settingsFront?.BannersRectangular) && (
                    <Route
                        path='/banners'
                        element={
                            <CheckAuth>
                                <Banners />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.OrdersPage && (
                    <Route
                        path='/orders'
                        element={
                            <CheckAuth>
                                <OrdersPage />
                            </CheckAuth>
                        }
                    />
                )}
                <Route path='/no-domain' element={<NoDomainPage />} />
                {
                    <Route
                        path='*'
                        element={
                            <CheckAuth>
                                <Notfound />
                            </CheckAuth>
                        }
                    />
                }
                {<Route path='/auth' element={<AuthPage />} />}

                {settingsFront?.StoriesPage && (
                    <Route
                        path='/stories'
                        element={
                            <CheckAuth>
                                <CheckRole>
                                    <StoriesPage />
                                </CheckRole>
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.StoriesPageYm && (
                    <Route
                        path='/stories-ym'
                        element={
                            <CheckAuth>
                                <StoriesPageYm />
                            </CheckAuth>
                        }
                    />
                )}
                {settingsFront?.adminPanel && (
                    <Route
                        path='/admin-panel'
                        element={
                            <CheckAuth>
                                <AdminPanel />
                            </CheckAuth>
                        }
                    />
                )}
            </Routes>
        </>
    );
};

export default App;
