import { TweenOneGroup } from 'rc-tween-one';
import TagItem from '../tag/Tag';

const TagComponent = ({ tagsList, setTagsList, deleteHandler }) => {
    const handleClose = (removedTag) => {
        const newTags = tagsList.filter((tag) => Number(tag.id) !== Number(removedTag));
        deleteHandler(removedTag);
        setTagsList(newTags);
    };

    const forMap = (tag) => {
        return (
            <span key={tag.id} style={{ display: 'inline-block' }}>
                <TagItem
                    closable={true}
                    handleClose={handleClose}
                    value={tag.id}
                    name={tag.name}
                />
            </span>
        );
    };

    const tagChild = tagsList.map(forMap);

    return (
        <>
            <div style={{ marginBottom: 16 }}>
                <TweenOneGroup
                    style={{ rowGap: '6px', display: 'flex', flexWrap: 'wrap' }}
                    appear={false}
                    enter={{ scale: 0.8, opacity: 0, type: 'from', duration: 100 }}
                    leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
                    onEnd={(e) => {
                        if (e.type === 'appear' || e.type === 'enter') {
                            e.style = 'display: inline-block';
                        }
                    }}
                >
                    {tagChild}
                </TweenOneGroup>
            </div>
        </>
    );
};

export default TagComponent;
