import './CitiesMenu.scss';
import { useSelector, useDispatch } from 'react-redux';
import dataService from '../../services/dataService';
import { updateCity } from '../../store/actions';
import { useEffect, useState } from 'react';

const CitiesMenu = ({ onSelect }) => {
    const dtService = new dataService();
    const { token } = useSelector((s) => s);
    const dispatch = useDispatch();
    const [citiesList, setCitiesList] = useState([]);
    const LOCAL_STORAGE = window.localStorage;

    useEffect(() => {
        if (token) {
            dtService.getCities(token).then((res) => {
                setCitiesList(res);
                if (!!LOCAL_STORAGE.getItem('gs-city')) {
                    return;
                } else {
                    dispatch(updateCity(res[0]));
                }
            });
        }
    }, [token]);

    return (
        <div className='CitiesMenu'>
            {citiesList.map((item) => (
                <div
                    className='CitiesMenu__item'
                    onClick={() => onSelect && onSelect(item)}
                >
                    {item?.title}
                </div>
            ))}
        </div>
    );
};

export default CitiesMenu;
