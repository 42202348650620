import './BasketPage.scss';
import HeaderProfile from '../../components/HeaderProfile/HeaderProfile';
import { Col, message, Row } from 'antd';
import Input from '../../components/Input/Input';
import Pl from '../../components/Pl/Pl';
import BasketExList from './components/BasketExList/BasketExList';
import BasketCutleryList from './components/BasketCutleryList/BasketCutleryList';
import BasketPromo from './components/BasketPromo/BasketPromo';
import BasketRec from './components/BasketRec/BasketRec';
import Button from '../../components/Button/Button';

import Checkbox from '../../components/Checkbox/Checkbox';
import BasketOnlinePay from './components/BasketOnlinePay/BasketOnlinePay';
import BasketGift from './components/BasketGift/BasketGift';
import { useState } from 'react';
import BasketTable from './modals/BasketTable/BasketTable';

import { motion } from 'framer-motion';
import pageEnterAnimProps from '../../funcs/pageEnterAnimProps';
import { useSelector } from 'react-redux';
import bsService from '../../services/bsService';
import { useEffect } from 'react';
import SaveIcon from '../../icons/SaveIcon/SaveIcon';
import catService from '../../services/catService';

const bs = new bsService();
const cs = new catService();

// const AllowedDeliveryTypesObj = {
//     onlyDelivery: '0',
//     onlyLocal: '1',
//     both: '2',
//     none: '3'
// }

// const AllowedPayDeliveryTypesObj = {
//     onlyDelivery: '0',
//     onlyLocal: '1',
//     both: '2',
//     none: '3'
// }

const BasketPage = () => {
    const { token, city } = useSelector((state) => state);

    //data
    const [CartAdditions, setCartAdditions] = useState([]);
    const [CartAdditionsTable, setCartAdditionsTable] = useState([]);
    const [CartCutlery, setCartCutlery] = useState([]);
    const [CartGlobalRecomendations, setCartGlobalRecomendations] = useState([]);
    const [cartMainData, setcartMainData] = useState([]);
    const [Gifts, setGifts] = useState([]);
    const [Promocodes, setPromocodes] = useState([]);

    //cart main data
    const [AllowedDeliveryTypes, setAllowedDeliveryTypes] = useState('0');
    const [AllowedPayDeliveryTypes, setAllowedPayDeliveryTypes] = useState('0');
    const [CanHaveGiftsWhenSale, setCanHaveGiftsWhenSale] = useState('0');
    const [CanHavePromocodeWhenSale, setCanHavePromocodeWhenSale] = useState('0');
    const [CountOfPersonalRecomendations, setCountOfPersonalRecomendations] =
        useState('0');
    const [ID, setID] = useState('0');
    const [IsHaveMultipleGifts, setIsHaveMultipleGifts] = useState('0');
    const [MaxBonusesPayment, setMaxBonusesPayment] = useState('');
    const [MaxBonusesPercent, setMaxBonusesPercent] = useState('');
    // const [BonusesPercent, setBonusesPercent] = useState('')
    const [OnlyPayOnlineWhen, setOnlyPayOnlineWhen] = useState('0');
    const [OrderBonusesGetMax, setOrderBonusesGetMax] = useState('');
    const [OrderBonusesGetPercent, setOrderBonusesGetPercent] = useState('');
    const [OrderBonusesGetMaxPartner, setOrderBonusesGetMaxPartner] = useState('');
    const [OrderBonusesGetPercentPartner, setOrderBonusesGetPercentPartner] =
        useState('');
    const [PaidAdditionID, setPaidAdditionID] = useState('');
    const [PriceForAddition, setPriceForAddition] = useState('');
    const [PriceForCutlery, setPriceForCutlery] = useState('');

    //chchchchc
    const [payBonusDel, setPayBonusDel] = useState('0');
    const [payBonusLocal, setPayBonusLocal] = useState('0');
    const [bonusDel, setBonusDel] = useState('0');
    const [bonusLocal, setBonusLocal] = useState('0');

    const [selectList, setSelectList] = useState([]);

    useEffect(() => {
        switch (AllowedDeliveryTypes?.toString()) {
            case '0':
                setBonusDel('1');
                setBonusLocal('0');
                break;
            case '1':
                setBonusDel('0');
                setBonusLocal('1');
                break;
            case '2':
                setBonusDel('1');
                setBonusLocal('1');
                break;
            case '3':
                setBonusDel('0');
                setBonusLocal('0');
                break;
            default:
                setBonusDel('0');
                setBonusLocal('0');
        }
    }, [AllowedDeliveryTypes]);

    useEffect(() => {
        switch (AllowedPayDeliveryTypes?.toString()) {
            case '0':
                setPayBonusDel('1');
                setPayBonusLocal('0');
                break;
            case '1':
                setPayBonusDel('0');
                setPayBonusLocal('1');
                break;
            case '2':
                setPayBonusDel('1');
                setPayBonusLocal('1');
                break;
            case '3':
                setPayBonusDel('0');
                setPayBonusLocal('0');
                break;
            default:
                setPayBonusDel('0');
                setPayBonusLocal('0');
                break;
        }
    }, [AllowedPayDeliveryTypes]);

    const [basketTable, setBasketTable] = useState(false);
    const [load, setLoad] = useState(false);

    const getSettings = () => {
        bs.getBasketSettings(token, { cityId: city.id }).then((res) => {
            setCartAdditions(res?.CartAdditions);
            setCartAdditionsTable(res?.CartAdditionsTable);
            setCartCutlery(res?.CartCutlery);
            setCartGlobalRecomendations(res?.CartGlobalRecomendations ?? []);
            setGifts(res?.Gifts);
            setPromocodes(res?.Promocodes);

            //set main data
            setAllowedDeliveryTypes(res?.cartMainData?.AllowedDeliveryTypes?.toString());
            setAllowedPayDeliveryTypes(
                res?.cartMainData?.AllowedPayDeliveryTypes?.toString()
            );
            setCanHaveGiftsWhenSale(res?.cartMainData?.CanHaveGiftsWhenSale);
            setCanHavePromocodeWhenSale(res?.cartMainData?.CanHavePromocodeWhenSale);
            setCountOfPersonalRecomendations(
                res?.cartMainData?.CountOfPersonalRecomendations
            );
            setID(res?.cartMainData?.ID);
            setIsHaveMultipleGifts(res?.cartMainData?.IsHaveMultipleGifts);
            setMaxBonusesPayment(res?.cartMainData?.MaxBonusesPayment);
            setMaxBonusesPercent(res?.cartMainData?.MaxBonusesPercent);
            setOnlyPayOnlineWhen(res?.cartMainData?.OnlyPayOnlineWhen);
            setOrderBonusesGetMax(res?.cartMainData?.OrderBonusesGetMax);
            setOrderBonusesGetPercent(res?.cartMainData?.OrderBonusesGetPercent);
            setOrderBonusesGetMaxPartner(res?.cartMainData?.OrderBonusesGetMaxPartner);
            setOrderBonusesGetPercentPartner(
                res?.cartMainData?.OrderBonusesGetPercentPartner
            );
            setPaidAdditionID(res?.cartMainData?.PaidAdditionID);
            setPriceForAddition(res?.cartMainData?.PriceForAddition);
            setPriceForCutlery(res?.cartMainData?.PriceForCutlery);
        });
    };

    useEffect(() => {
        if (token) {
            getSettings();
        }
    }, [token]);

    const openBasketTable = () => {
        setBasketTable(true);
    };

    const closeBasketTable = () => {
        setBasketTable(false);
        setCartAdditionsTable(CartAdditionsTable);
    };

    const switchDelTypes = (del, local) => {
        if (del == '1' && local == '1') {
            return '2';
        }
        if (del == '1' && local == '0') {
            return '0';
        }
        if (del == '0' && local == '1') {
            return '1';
        }
        if (del == '0' && local == '0') {
            return '3';
        }
    };

    const onSave = () => {
        setLoad(true);

        const body = {
            CartAdditions: CartAdditions.map((item) => {
                delete item.index;
                return item;
            }),
            CartAdditionsTable: CartAdditionsTable.map((item) => {
                delete item.index;
                return item;
            }),
            CartCutlery: CartCutlery.map((item) => {
                delete item.index;
                return item;
            }),
            CartGlobalRecomendations: CartGlobalRecomendations?.map((i) => {
                return {
                    ID: i.ID,
                    PlateID: i.PlateID,
                };
            }),
            cartMainData: {
                AllowedDeliveryTypes: switchDelTypes(bonusDel, bonusLocal),
                AllowedPayDeliveryTypes: switchDelTypes(payBonusDel, payBonusLocal),
                CanHaveGiftsWhenSale,
                CanHavePromocodeWhenSale,
                CountOfPersonalRecomendations,
                ID,
                IsHaveMultipleGifts,
                MaxBonusesPayment,
                MaxBonusesPercent,
                OnlyPayOnlineWhen,
                OrderBonusesGetMax,
                OrderBonusesGetPercent,
                OrderBonusesGetMaxPartner,
                OrderBonusesGetPercentPartner,
                PaidAdditionID,
                PriceForAddition,
                PriceForCutlery,
            },
            Gifts: Gifts.map((item) => {
                delete item.index;
                return item;
            }),
            Promocodes: Promocodes.map((item) => {
                delete item.index;
                return item;
            }),
            cityId: city.id,
        };

        bs.editBasketSettings(token, body)
            .then((res) => {
                getSettings();
                if (res) {
                    message.success('Настройки успешно сохранены');
                } else message.error('Произошла ошибка');
            })
            .catch((err) => {
                message.error('Произошла ошибка');
            })
            .finally((_) => {
                setLoad(false);
            });
    };

    useEffect(() => {
        if (token) {
            cs.getCatsNames(token, { elements: 'plates', cityId: city.id }).then(
                (res) => {
                    setSelectList(
                        res.map((i) => {
                            return {
                                ...i,
                                value: i?.ID,
                                label: i.Name,
                            };
                        })
                    );
                }
            );

            cs.getSearchProd(token, { elements: 'plates' }).then((res) => {});
        }
    }, [token]);

    return (
        <motion.div {...pageEnterAnimProps} className='BasketPage page'>
            <HeaderProfile />

            <BasketTable
                setCartAdditionsTable={setCartAdditionsTable}
                data={CartAdditionsTable}
                visible={basketTable}
                close={closeBasketTable}
            />

            <div className='pageBody'>
                <div className='BasketPage__body pageBody-content'>
                    <Row gutter={[30, 0]}>
                        <Col span={12}>
                            {/* {window.location.origin === 'https://ibazar.gscore.ru' ||
                            window.location.origin ===
                                'https://ident.gscore.ru' ? null : (
                                <Row className='row-custom'>
                                    <Input
                                        maskType={String}
                                        value={PriceForAddition?.toString() ?? ''}
                                        onChange={(e) =>
                                            setPriceForAddition(e.target.value)
                                        }
                                        placeholder={'Цена за платное дополнение'}
                                    />
                                </Row>
                            )} */}
                            <Row className='row-custom'>
                                <Pl
                                    style={{
                                        backgroundColor: '#fff',
                                        justifyContent: 'flex-start',
                                        height: 'unset',
                                        color: '#7B99FF',
                                    }}
                                    text={'Таблица дополнений'}
                                    onClick={openBasketTable}
                                />
                            </Row>
                            {window.location.origin === 'https://ibazar.gscore.ru' ||
                            window.location.origin ===
                                'https://ident.gscore.ru' ? null : (
                                <>
                                    <Row className='row-custom'>
                                        <BasketExList
                                            selectList={selectList}
                                            setData={setCartAdditions}
                                            data={CartAdditions}
                                        />
                                    </Row>
                                    <Row className='row-custom'>
                                        <BasketCutleryList
                                            selectList={selectList}
                                            setData={setCartCutlery}
                                            data={CartCutlery}
                                            setPriceForCultery={setPriceForCutlery}
                                            priceForCultery={PriceForCutlery}
                                        />
                                    </Row>
                                </>
                            )}

                            <Row className='row-custom'>
                                <BasketPromo
                                    selectList={selectList}
                                    setData={setPromocodes}
                                    data={Promocodes}
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Checkbox
                                    checked={CanHavePromocodeWhenSale == '1'}
                                    onChange={(e) =>
                                        e.target.checked
                                            ? setCanHavePromocodeWhenSale('1')
                                            : setCanHavePromocodeWhenSale('0')
                                    }
                                    text={'Промокоды при активной скидке'}
                                    id='5'
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Checkbox
                                    checked={CanHaveGiftsWhenSale == '1'}
                                    onChange={(e) =>
                                        e.target.checked
                                            ? setCanHaveGiftsWhenSale('1')
                                            : setCanHaveGiftsWhenSale('0')
                                    }
                                    text={'Подарки при активной скидке'}
                                    id='6'
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Input
                                    maskType={String}
                                    value={
                                        CountOfPersonalRecomendations?.toString() ?? ''
                                    }
                                    onChange={(e) =>
                                        setCountOfPersonalRecomendations(e?.target?.value)
                                    }
                                    placeholder={'Количество персональных рекомендаций'}
                                />
                            </Row>

                            <Row className='row-custom'>
                                <BasketRec
                                    selectList={selectList}
                                    data={CartGlobalRecomendations}
                                    setData={setCartGlobalRecomendations}
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Button
                                    disabled={false}
                                    load={load}
                                    onClick={onSave}
                                    styles={{ width: '100%' }}
                                    text={'Сохранить'}
                                    before={<SaveIcon color={'#fff'} size={16} />}
                                />
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row className='row-custom'>
                                <Input
                                    value={MaxBonusesPercent?.toString() ?? ''}
                                    onChange={(e) =>
                                        setMaxBonusesPercent(e?.target?.value)
                                    }
                                    maskType={String}
                                    placeholder={'Процент бонусов для оплаты'}
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Input
                                    value={MaxBonusesPayment?.toString() ?? ''}
                                    onChange={(e) =>
                                        setMaxBonusesPayment(e?.target?.value)
                                    }
                                    maskType={String}
                                    placeholder={
                                        'Максимальное количество бонусов для оплаты'
                                    }
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Checkbox
                                    checked={bonusDel == '1'}
                                    onChange={(e) =>
                                        e.target.checked
                                            ? setBonusDel('1')
                                            : setBonusDel('0')
                                    }
                                    text={'Оплата бонусами для доставки'}
                                    id='1'
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Checkbox
                                    checked={bonusLocal == '1'}
                                    onChange={(e) =>
                                        e.target.checked
                                            ? setBonusLocal('1')
                                            : setBonusLocal('0')
                                    }
                                    text={'Оплата бонусами для самовывоза'}
                                    id='2'
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Input
                                    maskType={String}
                                    value={OrderBonusesGetPercent?.toString() ?? ''}
                                    onChange={(e) =>
                                        setOrderBonusesGetPercent(e?.target?.value)
                                    }
                                    placeholder={'Процент получаемых бонусов'}
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Input
                                    value={OrderBonusesGetMax?.toString() ?? ''}
                                    onChange={(e) =>
                                        setOrderBonusesGetMax(e.target.value)
                                    }
                                    maskType={String}
                                    placeholder={
                                        'Максимальный процент получаемых бонусов'
                                    }
                                />
                            </Row>
                            {window.location.origin === 'https://macarons.gscore.ru' ||
                            window.location.origin === 'http://localhost:3000' ? (
                                <>
                                    <Row className='row-custom'>
                                        <Input
                                            maskType={String}
                                            value={
                                                OrderBonusesGetPercentPartner?.toString() ??
                                                ''
                                            }
                                            onChange={(e) =>
                                                setOrderBonusesGetPercentPartner(
                                                    e.target.value
                                                )
                                            }
                                            placeholder={
                                                'Процент получаемых бонусов у партнёра'
                                            }
                                        />
                                    </Row>
                                    <Row className='row-custom'>
                                        <Input
                                            value={
                                                OrderBonusesGetMaxPartner?.toString() ??
                                                ''
                                            }
                                            onChange={(e) =>
                                                setOrderBonusesGetMaxPartner(
                                                    e.target.value
                                                )
                                            }
                                            maskType={String}
                                            placeholder={
                                                'Максимальный процент получаемых бонусов у партнёра'
                                            }
                                        />
                                    </Row>
                                </>
                            ) : null}
                            <Row className='row-custom'>
                                <Checkbox
                                    checked={payBonusDel == '1'}
                                    onChange={(e) =>
                                        e.target.checked
                                            ? setPayBonusDel('1')
                                            : setPayBonusDel('0')
                                    }
                                    text={'Получение бонусов при доставке'}
                                    id='3'
                                />
                            </Row>
                            <Row className='row-custom'>
                                <Checkbox
                                    checked={payBonusLocal == '1'}
                                    onChange={(e) =>
                                        e.target.checked
                                            ? setPayBonusLocal('1')
                                            : setPayBonusLocal('0')
                                    }
                                    text={'Получение бонусов при самовывозе'}
                                    id='4'
                                />
                            </Row>
                            <Row className='row-custom'>
                                <BasketOnlinePay
                                    value={OnlyPayOnlineWhen?.toString() ?? ''}
                                    setValue={setOnlyPayOnlineWhen}
                                />
                            </Row>
                            <Row className='row-custom'>
                                <BasketGift
                                    selectList={selectList}
                                    data={Gifts}
                                    setData={setGifts}
                                />
                            </Row>
                        </Col>
                    </Row>
                </div>
            </div>
        </motion.div>
    );
};

export default BasketPage;
